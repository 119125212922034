import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import "flatpickr/dist/flatpickr.min.css";
import { fetchSWKProperty } from '../../services/SWKPropertService';
import { fetchFlats, fetchFlatById } from '../../services/FlatMasterService';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { addorderManagement } from '../../services/orderManagementService';
import { fetchOnboardingCustomersById } from '../../services/onboardingCustomerService';
import { validateOrderDetails } from '../../utils/validation';
import "flatpickr/dist/flatpickr.min.css";
import Flatpickr from "react-flatpickr";
import { Link } from 'react-router-dom';
import { handleErrors } from '../../utils/errorHandler';
import AsyncSelect from 'react-select/async';
import { usePageLevelAccess } from '../../hooks/usePageLevelAccess';

export const OrderManagementDetails = () => {
    const { userGuid } = useParams();
    const [properties, setProperties] = useState([]);
    const [customerName, setCustomerName] = useState('');
    const [customerEmail, setCustomerEmail] = useState('');
    const [customerPhone, setCustomerPhone] = useState('');
    const [flats, setFlats] = useState([]);
    const [selectedProperty, setSelectedProperty] = useState('');
    const [selectedFlat, setSelectedFlat] = useState('');
    const [stayTypeOptions, setStayTypeOptions] = useState([]);
    const [stayType, setStayType] = useState('');
    const [stayDuration, setStayDuration] = useState('');
    const [flatPricing, setFlatPricing] = useState({});
    const [rentAmount, setRentAmount] = useState(0);
    const [securityDeposit, setSecurityDeposit] = useState(0);
    const [paymentType, setPaymentType] = useState('');
    const [onboardingCharges, setOnboardingCharges] = useState(2000);
    const [maintenanceCharges, setMaintenanceCharges] = useState(0);
    const [waterBill, setWaterBill] = useState(0);
    const [paidAmount, setPaidAmount] = useState(0);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const navigate = useNavigate();
    const [values, setValues] = useState({
        userName: '', emailAddress: '', contactNo: '', moveInDate: '', stayType: '', stayCount: '', noOfGuest: '', flatId: '', rentAmount: '', securityDeposit: '', onboardingCharge: '', maintenanceCharge: '', waterBill: '', paidAmount: '', paymentReferenceNo: '', payType: ''
    });
    const [errors, setErrors] = useState({});
    const PageLevelAccessurl = '/onboarding-customers/add-order/:userGuid';
    const { pageAccessData} = usePageLevelAccess(PageLevelAccessurl);

    useEffect(() => {
      if (pageAccessData) {
          if (!pageAccessData.addAccess) {
              navigate('/404-error-page');
          } else {
              return;
          }

      } else {
          console.log('No page access details found');
      }
  })
    useEffect(() => {
        const loadCustomerDetails = async () => {
            try {
                const data = await fetchOnboardingCustomersById(userGuid);
                setCustomerName(data.result.firstName);
                setCustomerEmail(data.result.emailAddress);
                setCustomerPhone(data.result.phoneNo);
            } catch (error) {
                console.error('Error fetching properties:', error);
            }
        };

        loadCustomerDetails();
    }, [userGuid]);

    useEffect(() => {
        const loadProperties = async () => {
            try {
                const data = await fetchSWKProperty();
                setProperties(data);
            } catch (error) {
                console.error('Error fetching properties:', error);
            }
        };

        loadProperties();
    }, []);

    useEffect(() => {
        if (!selectedProperty) return;

        const loadFlats = async () => {
            try {
                const flatsData = await fetchFlats(selectedProperty);
                setFlats(flatsData);
            } catch (error) {
                console.error('Error fetching flats:', error);
            }
        };

        loadFlats();
    }, [selectedProperty]);

    useEffect(() => {
        if (!selectedFlat) return;

        const loadFlatDetails = async () => {
            try {
                const flatData = await fetchFlatById(selectedFlat);
                setFlatPricing(flatData.flatPricing);

                const stayTypes = flatData.stayType.split(',').map((type) => type.trim());

                if (stayTypes.includes("Daily")) {
                    setStayTypeOptions(["Daily", "Monthly"]);
                    setStayType("Daily");
                } else {
                    setStayTypeOptions(["Monthly"]);
                    setStayType("Monthly");
                }
                // setOnboardingCharges(flatData.flatPricing.onboardingCharges || 0);
                setMaintenanceCharges(flatData.flatPricing.flatMaintenance || 0);
                setWaterBill(flatData.flatPricing.fixedWaterBill || 0);
            } catch (error) {
                handleErrors(error);
            }
        };

        loadFlatDetails();
    }, [selectedFlat]);

    useEffect(() => {
        if (stayType && stayDuration) {
            let rent = 0;
            let deposit = 0;

            if (stayType === "Daily") {
                rent = flatPricing.dailyRent || 0;
                deposit = flatPricing.dailyRentDeposit || 0;
            } else if (stayType === "Monthly") {
                const months = parseInt(stayDuration, 10);
                if (months < 6) {
                    rent = flatPricing.monthlyRentST || 0;
                    deposit = flatPricing.monthlyRentSTDeposit || 0;
                } else {
                    rent = flatPricing.monthlyRentLT || 0;
                    deposit = flatPricing.monthlyRentLTDeposit || 0;
                }
            }

            setRentAmount(rent);
            setSecurityDeposit(deposit);
        }
    }, [stayType, stayDuration, flatPricing]);

    useEffect(() => {
        let total = 0;
        if (paymentType === "Full") {
            total = rentAmount + securityDeposit + onboardingCharges + maintenanceCharges + waterBill;
        } else if (paymentType === "Token") {
            total = onboardingCharges + 10000;
        }
        setPaidAmount(total);
    }, [paymentType, rentAmount, securityDeposit, onboardingCharges, maintenanceCharges, waterBill]);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const handlePhoneChange = (contactNo) => setValues({ ...values, contactNo: contactNo });

    const handleDateChange = (selectedDate) => {
        const formattedDate = selectedDate[0].toISOString();
        setValues((prevData) => ({
            ...prevData,
            moveInDate: formattedDate,
        }));
    };

    const loadPropertyOptions = (inputValue, callback) => {
        const filteredProperties = properties.filter((property) =>
            property.propertyName.toLowerCase().includes(inputValue.toLowerCase())
        );
        callback(
            filteredProperties.map((property) => ({
                label: property.propertyName,
                value: property.propertyGuid,
            }))
        );
    };

    const loadFlatOptions = (inputValue, callback) => {
        const filteredFlats = flats.filter((flat) =>
            flat.flatName.toLowerCase().includes(inputValue.toLowerCase())
        );
        callback(
            filteredFlats.map((flat) => ({
                label: flat.flatName,
                value: flat.id,
            }))
        );
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateOrderDetails({ ...values, userName: customerName, emailAddress: customerEmail, contactNo: customerPhone, flatId: selectedFlat, stayType: stayType, stayCount: stayDuration, rentAmount: rentAmount, onboardingCharge: onboardingCharges, maintenanceCharge: maintenanceCharges, waterBill: waterBill, paidAmount: paidAmount });
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            try {
                const payload = {
                    userGuid: userGuid,
                    userName: customerName,
                    emailAddress: customerEmail,
                    contactNo: customerPhone,
                    moveInDate: values.moveInDate,
                    stayType: stayType,
                    stayCount: stayDuration,
                    noOfGuest: values.noOfGuest,
                    flatId: selectedFlat,
                    rentAmount: rentAmount,
                    securityDeposit: securityDeposit,
                    onboardingCharge: onboardingCharges,
                    maintenanceCharge: maintenanceCharges,
                    waterBill: waterBill,
                    paidAmount: paidAmount,
                    paymentReferenceNo: values.paymentReferenceNo,
                    payType: paymentType,
                };
                setIsButtonDisabled(true);
                const response = await addorderManagement(payload);
                toast.success("Order added successful!");
                navigate(`/orders/detail/${response.order_id}`);
                setIsButtonDisabled(false);
            } catch (error) {
                handleErrors(error);
                setIsButtonDisabled(false);
            } finally {
                setIsButtonDisabled(false);
            }
        }
    };
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">Order Management Details</h4>
                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">
                                    <Link to="/">
                                        <i className="ri-home-2-fill"></i>
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to='/onboarding-customers'>
                                        Manage Onboard Customers
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">
                                    Add Order
                                </li>

                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-xxl-8">
                        <div className="row">
                            <div className="card mt-xxl-n5">
                                <div className="card-header">
                                    <h5 className="mb-sm-1 mt-sm-1">Customer Details</h5>
                                </div>
                                <div className="card-body p-4">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6 col-sm-12 col-md-6 col-sm-12">
                                            <div className="mb-3">
                                                <label htmlFor="userName" className="form-label">Customer Name <span className='required-field'>*</span></label>
                                                <input
                                                    type="text"
                                                    name="userName"
                                                    value={customerName}
                                                    placeholder='Enter Customer Name'
                                                    onChange={(e) => setCustomerName(e.target.value)}
                                                    className={`form-control ${errors.userName ? 'is-invalid' : ''}`}
                                                />
                                                {errors.userName && <p className="text-danger">{errors.userName}</p>}
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                            <div className="mb-3">
                                                <label htmlFor="emailAddress" className="form-label">Email <span className='required-field'>*</span></label>
                                                <input
                                                    type="email"
                                                    name="emailAddress"
                                                    value={customerEmail}
                                                    onChange={(e) => setCustomerPhone(e.target.value)}
                                                    placeholder='Enter Customer Email'
                                                    className={`form-control ${errors.emailAddress ? 'is-invalid' : ''}`}
                                                />
                                                {errors.emailAddress && <p className="text-danger">{errors.emailAddress}</p>}
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                            <div className="mb-3">
                                                <label htmlFor="phonenumber" className="form-label">Phone Number <span className='required-field'>*</span></label>
                                                <PhoneInput
                                                    international
                                                    onChange={handlePhoneChange}
                                                    value={customerPhone}
                                                    defaultCountry='IN'
                                                    maxLength="15"
                                                    placeholder='Enter Phone Number'
                                                />
                                                {errors.contactNo && <div style={{ color: '#dc3545' }}>{errors.contactNo}</div>}
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="card mt-xxl-n5">
                                <div className="card-header">
                                    <h5 className="mb-sm-1 mt-sm-1">Property Details</h5>
                                </div>
                                <div className="card-body p-4">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                            <div className="mb-3">
                                                <label htmlFor="userName" className="form-label">Properties</label>
                                                <AsyncSelect
                                                    cacheOptions
                                                    loadOptions={loadPropertyOptions}
                                                    defaultOptions={properties.map((property) => ({
                                                        label: property.propertyName,
                                                        value: property.propertyGuid,
                                                    }))}
                                                    onChange={(selectedOption) => setSelectedProperty(selectedOption?.value || '')}
                                                    value={properties.find((property) => property.propertyGuid === selectedProperty) ? {
                                                        label: properties.find((property) => property.propertyGuid === selectedProperty)?.propertyName,
                                                        value: selectedProperty,
                                                    } : null}
                                                    isClearable
                                                    placeholder="Select Property"
                                                    className={errors.propertyGuid ? 'is-invalid' : ''}
                                                />

                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                            <div className="mb-3">
                                                <label htmlFor="flatSelect" className="form-label">Select Flat <span className='required-field'>*</span></label>
                                                <AsyncSelect
                                                    cacheOptions
                                                    loadOptions={loadFlatOptions}
                                                    defaultOptions={flats.map((flat) => ({
                                                        label: flat.flatName,
                                                        value: flat.id,
                                                    }))}
                                                    onChange={(selectedOption) => setSelectedFlat(selectedOption?.value || '')}
                                                    value={flats.find((flat) => flat.id === selectedFlat) ? {
                                                        label: flats.find((flat) => flat.id === selectedFlat)?.flatName,
                                                        value: selectedFlat,
                                                    } : null}
                                                    isClearable
                                                    placeholder="Select Flat"
                                                    className={errors.flatId ? 'is-invalid' : ''}
                                                />
                                                {errors.flatId && <p className="text-danger">{errors.flatId}</p>}

                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                            <div className="mb-3">
                                                <label htmlFor="staytype" className="form-label">Stay Type <span className='required-field'>*</span></label>
                                                {stayTypeOptions.length > 1 ? (
                                                    <select
                                                        id="stayTypeSelect"
                                                        className={`form-select ${errors.stayType ? 'is-invalid' : ''}`}
                                                        onChange={(e) => setStayType(e.target.value)}
                                                        value={stayType}
                                                    >
                                                        {stayTypeOptions.map((option) => (
                                                            <option key={option} value={option}>
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>
                                                ) : (
                                                    <input type="text" className={`form-control ${errors.stayType ? 'is-invalid' : ''}`} value={stayType} readOnly />
                                                )}
                                                {errors.stayType && <p className="text-danger">{errors.stayType}</p>}
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                            <div className="mb-3">

                                                <label className="form-label">
                                                    {stayType === "Daily" ? "No of Days:" : "No of Months:"} <span className='required-field'>*</span>
                                                </label>
                                                <input
                                                    type="number"
                                                    className={`form-control ${errors.stayCount ? 'is-invalid' : ''}`}
                                                    value={stayDuration}
                                                    onChange={(e) => setStayDuration(e.target.value)}
                                                    min={1}
                                                    placeholder={stayType === "Daily" ? "Enter number of days" : "Enter number of months"}
                                                />
                                                {errors.stayCount && <p className="text-danger">{errors.stayCount}</p>}
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                            <div className="mb-3">
                                                <label htmlFor="noOfGuest" className="form-label">No of Guests <span className='required-field'>*</span></label>
                                                <input
                                                    type="number"
                                                    name="noOfGuest"
                                                    value={values.noOfGuest}
                                                    onChange={handleChange}
                                                    className={`form-control ${errors.noOfGuest ? 'is-invalid' : ''}`}
                                                    min={1}
                                                    placeholder='Enter Number of Guests'
                                                />
                                                {errors.noOfGuest && <p className="text-danger">{errors.noOfGuest}</p>}
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                            <div className="mb-3">
                                                <label htmlFor="rentAmount" className="form-label">Rent Amount <span className='required-field'>*</span></label>
                                                <input
                                                    type="number"
                                                    className={`form-control ${errors.rentAmount ? 'is-invalid' : ''}`}
                                                    value={rentAmount}
                                                    onChange={(e) => setRentAmount(Number(e.target.value))}
                                                    disabled
                                                />
                                                {errors.rentAmount && <p className="text-danger">{errors.rentAmount}</p>}
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                            <div className="mb-3">
                                                <label htmlFor="securityDeposit" className="form-label">Security Deposit</label>
                                                <input
                                                    type="number"
                                                    className={`form-control ${errors.securityDeposit ? 'is-invalid' : ''}`}
                                                    value={securityDeposit}
                                                    onChange={(e) => setSecurityDeposit(Number(e.target.value))}
                                                    disabled
                                                />
                                                {errors.securityDeposit && <p className="text-danger">{errors.securityDeposit}</p>}
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                            <div className="mb-3">
                                                <label htmlFor="onboardingCharges" className="form-label">Onboarding Charges <span className='required-field'>*</span></label>
                                                <input
                                                    type="number"
                                                    className={`form-control ${errors.onboardingCharge ? 'is-invalid' : ''}`}
                                                    value={onboardingCharges}
                                                    onChange={(e) => setOnboardingCharges(Number(e.target.value))}
                                                    disabled
                                                />
                                                {errors.onboardingCharge && <p className="text-danger">{errors.onboardingCharge}</p>}
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                            <div className="mb-3">
                                                <label htmlFor="maintenanceCharges" className="form-label">Maintainence Charges <span className='required-field'>*</span></label>
                                                <input
                                                    type="number"
                                                    className={`form-control ${errors.maintenanceCharge ? 'is-invalid' : ''}`}
                                                    value={maintenanceCharges}
                                                    onChange={(e) => setMaintenanceCharges(Number(e.target.value))}
                                                    disabled
                                                />
                                                {errors.maintenanceCharge && <p className="text-danger">{errors.maintenanceCharge}</p>}
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                            <div className="mb-3">
                                                <label htmlFor="waterBill" className="form-label">Water Bill <span className='required-field'>*</span></label>
                                                <input
                                                    type="number"
                                                    className={`form-control ${errors.waterBill ? 'is-invalid' : ''}`}
                                                    value={waterBill}
                                                    onChange={(e) => setWaterBill(Number(e.target.value))}
                                                    disabled
                                                />
                                                {errors.waterBill && <p className="text-danger">{errors.waterBill}</p>}
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                            <div className="mb-3">
                                                <label htmlFor="MoveInDate" className="form-label">Move In Date <span className='required-field'>*</span></label>
                                                <Flatpickr
                                                    data-enable-time
                                                    value={values.moveInDate}
                                                    onChange={handleDateChange}
                                                    className={`form-control ${errors.moveInDate ? 'is-invalid' : ''}`}
                                                    options={{
                                                        enableTime: true,
                                                        dateFormat: 'Y-m-d H:i',
                                                        time_24hr: true,
                                                        monthSelectorType: 'static',
                                                    }}
                                                    placeholder='Select Move In Date'
                                                />
                                                {errors.moveInDate && <p className="text-danger">{errors.moveInDate}</p>}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col xxl-4">
                        <div className="card mt-xxl-n5">
                            <div className="card-header">
                                <h5 className="mb-sm-1 mt-sm-1">Payment Details</h5>
                            </div>
                            <div className="card-body p-4">

                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label htmlFor="altEmail" className="form-label">Payment Type</label>
                                        <select
                                            id="paymentTypeSelect"
                                            className={`form-select ${errors.payType ? 'is-invalid' : ''}`}
                                            onChange={(e) => setPaymentType(e.target.value)}
                                            value={paymentType}
                                        >
                                            <option value="">Select Payment Type</option>
                                            <option value="Full">Full</option>
                                            <option value="Token">Token</option>
                                        </select>
                                        {errors.payType && <p className="text-danger">{errors.payType}</p>}
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label htmlFor="altEmail" className="form-label">Paid Amount <span className='required-field'>*</span></label>
                                        <div>
                                            <input
                                                type="number"
                                                className={`form-control ${errors.paidAmount ? 'is-invalid' : ''}`}
                                                value={paidAmount}
                                                onChange={(e) => setPaidAmount(Number(e.target.value))}
                                                disabled
                                            />
                                            {errors.paidAmount && <p className="text-danger">{errors.paidAmount}</p>}
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label htmlFor="paymentReferenceNo" className="form-label">Payment Reference No</label>
                                        <input
                                            type="text"
                                            name="paymentReferenceNo"
                                            value={values.paymentReferenceNo}
                                            onChange={handleChange}
                                            placeholder='Enter Payment Reference Number'
                                            className={`form-control ${errors.paymentReferenceNo ? 'is-invalid' : ''}`}
                                        />
                                        {errors.paymentReferenceNo && <p className="text-danger">{errors.paymentReferenceNo}</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button type="submit" className="btn btn-secondary" disabled={isButtonDisabled}>
                    {isButtonDisabled ? 'Saving...' : 'Save'}
                </button>
            </form>
        </>
    );
};
