import React from 'react';
import { MainLayout } from "../components/Common/MainLayout/mainlayout";
import { AuthenticationLayout } from '../components/Common/AuthLayout/AuthenticationLayout';
import { SignUpContent } from '../components/Authentication/SignUpContent';
import { SignInContent } from '../components/Authentication/SignInContent';
import { LockScreenContent } from '../components/Authentication/LockScreenContent';
import { LogOutContent } from '../components/Authentication/LogOutContent';
import { PasswordChangeContent } from '../components/Authentication/PasswordChangeContent';
import { PasswordResetContent } from '../components/Authentication/PasswordResetContent';
import { SuccessMsgContent } from '../components/Authentication/SuccessMsgContent';
import { ErrorPageContent } from '../components/Authentication/ErrorPageContent';
import { ErrorPageContentOne } from '../components/Authentication/ErrorPageContentOne';
import { TwoStepAuthContent } from '../components/Authentication/TwoStepAuthContent';
import { OfflinePageContent } from '../components/Authentication/OfflinePageContent';
import { PageGroup } from '../pages/PageGroup';
import { PageMaster } from '../pages/PageMaster';
import { CreateRoles } from '../pages/CreateRoles';
import { ManageAccessPage } from '../pages/ManageAccess';
import AuthProtected from "./AuthProtected";
import PublicRoute from "./PublicRoute";
import { AmenityMaster } from '../pages/AmenityMaster';
import { BHKType } from '../pages/BHKType';
import { LocationPage } from '../pages/LocationPage';
import { PropertyType } from '../pages/PropertyType';
import { CityPage } from '../pages/CityPage';
import { PropertyOwner } from '../pages/PropertyOwner';
import { PropertyOwnersUpdate } from '../pages/PropertyOwnersUpdate';
import { PropertyOwnerView } from '../pages/PropertyOwnerView';
import { SWRProperty } from '../pages/SWRProperty';
import { SWRPropertyView } from '../pages/SWRPropertyView';
import { SWRPropertyUpdate } from '../pages/SWRPropertyUpdate';
import { FlatMaster } from '../pages/FlatMaster';
import { ViewFullPropertyDetails } from '../pages/ViewFullPropertyDetails';
import { Newtrial } from '../pages/newtrial';
import { NewUser } from '../pages/NewUser';
import { NewUserView } from '../pages/NewUserView';
import { NewUserUpdate } from '../pages/NewUserUpdate';
import { ProfilePage } from '../pages/profilePage';
import { ChangePassword } from '../pages/ChangePassword';
import { BlogsAdd } from '../pages/BlogsAdd';
import { BlogsManage } from '../pages/BlogsManage';
import { CareTaker } from '../pages/CareTaker';
import { Supervisor } from '../pages/Supervisor';
import { SalesStaff } from '../pages/SalesStaff';
import { Handyman } from '../pages/Handyman';
import { OnboardingCustomers } from '../pages/OnboardingCustomers';
import { OnboardDetails } from '../pages/OnboardDetails';
import { ManageOnboardingCustomers } from '../pages/ManageOnboardingCustomers';
import { OrderManagement } from '../pages/OrderManagement';
import { ManageOrders } from '../pages/ManageOrders';
import { ManageOrdersDetails } from '../pages/ManageOrdersDetails';
import { DashboardPage } from '../pages/dashboardPage';
import { ProductMaster } from '../pages/ProductMaster';
import { VendorMaster } from '../pages/VendorMaster';
import { AddAsset } from '../pages/AddAsset';
import { ManageAssets } from '../pages/ManageAssets';
import { AssignAssets } from '../pages/AssignAssets';
import { ManageSupport } from '../pages/ManageSupport';
import { ManageSupportDetails } from '../pages/ManageSupportDetails';
import { AddSupportManagement } from '../pages/AddSupportManagement';
import { Navigate } from 'react-router-dom';
import { ManageActiveOnboardingCustomers } from '../pages/ManageActiveOnboardingCustomers';
import { ManagePropertyExp } from '../pages/ManagePropertyExp';
import { AddPropertyExp } from '../pages/AddPropertyExp';
import { ManageAudit } from '../pages/ManageAudit';
import { AddAudit } from '../pages/AddAudit';
import { UpdateAudit } from '../pages/UpdateAudit';
import { ManageReportsFlats } from '../pages/ManageReportsFlats';
import { ViewOwnerDetail } from '../pages/ViewOwnerDetail';
import { ManagePropertyListingReport } from '../pages/ManagePropertyListingReport';
import { ManageReportRent } from '../pages/ManageReportRent';
import { ManageReportMaintainence } from '../pages/ManageReportMaintainence';
import { ManageReportUtlity } from '../pages/ManageReportUtlity';
import { ManageReportOthers } from '../pages/ManageReportOthers';
import { UserDashboardPage } from '../pages/UserDashboardPage';
import { ManageReportsPropertyExpense } from '../pages/ManageReportsPropertyExpense';

const routes = [
  {
    path: '/',
    element: (
      <AuthProtected>
        <MainLayout />
      </AuthProtected>
    ),
    children: [
      { path: '/', element: <Navigate to="/dashboard" replace /> },
      { path: 'page-group', element: <PageGroup /> },
      { path: 'page-master', element: <PageMaster /> },
      { path: 'create-role', element: <CreateRoles /> },
      { path: 'Manage-role-access/:roleId/:roleName', element: <ManageAccessPage /> },
      { path: 'amenity-master', element: <AmenityMaster /> },
      { path: 'bhk-type', element: <BHKType /> },
      { path: 'location-master', element: <LocationPage /> },
      { path: 'property-type', element: <PropertyType /> },
      { path: 'city-master', element: <CityPage /> },
      { path: 'product-master', element: <ProductMaster /> },
      { path: 'vendor-master', element: <VendorMaster /> },

      //routing for property owners
      { path: 'property-owner', element: <PropertyOwnerView /> },
      {
        path: 'property-owner', children: [
          { path: 'add', element: <PropertyOwner /> },
          { path: 'update/:ownerId', element: <PropertyOwnersUpdate /> },
          { path: 'detail/:ownerguid', element: <ViewOwnerDetail /> },
        ]
      },
      //--------------------------


      //routing for user management
      { path: 'user', element: <NewUserView /> },
      {
        path: 'user', children: [
          { path: 'add', element: <NewUser /> },
          { path: 'update/:id', element: <NewUserUpdate /> },
        ]
      },
      //--------------------------


      //routing for  property and flat management
      { path: 'property', element: <SWRPropertyView /> },
      {
        path: 'property', children: [
          { path: 'add', element: <SWRProperty /> },
          { path: 'update/:id', element: <SWRPropertyUpdate /> },
          { path: 'detail/:id', element: <ViewFullPropertyDetails /> },

          {
            path: 'detail/', children: [
              { path: 'add-flat/:id', element: <FlatMaster /> },
              { path: 'update-flat/:flatid', element: <FlatMaster /> },
            ]
          },
        ]
      },
      //-----------------


      //routing for blog management
      { path: 'blogs', element: <BlogsManage /> },
      {
        path: 'blogs', children: [
          { path: 'add', element: <BlogsAdd /> },
          { path: 'update/:id', element: <BlogsAdd /> },
        ]
      },
      //------------------


      //routing for onboarding customers and orders management
      { path: 'onboarding-customers', element: <ManageOnboardingCustomers /> },
      { path: 'active-onboarding-customers', element: <ManageActiveOnboardingCustomers /> },
      {
        path: 'onboarding-customers', children: [
          { path: 'add', element: <OnboardingCustomers /> },
          { path: 'update/:customerGuid', element: <OnboardingCustomers /> },
          { path: 'detail/:customerGuid', element: <OnboardDetails /> },
          { path: 'add-order/:userGuid', element: <OrderManagement /> },
        ]
      },
      { path: 'orders', element: <ManageOrdersDetails /> },
      { path: 'current-month-orders/:month', element: <ManageOrdersDetails /> },
      {
        path: 'orders', children: [
          { path: 'detail/:orderId', element: <ManageOrders /> },
        ]
      },
      //-----------------

      //assign properties routing
      { path: 'assign-caretaker', element: <CareTaker /> },
      { path: 'assign-supervisor', element: <Supervisor /> },
      { path: 'assign-sales-staff', element: <SalesStaff /> },
      { path: 'assign-handyman', element: <Handyman /> },
      //------------------

      //asset management
      { path: 'assets', element: <ManageAssets /> },
      {
        path: 'assets', children: [
          { path: 'add', element: <AddAsset /> },
          { path: 'update/:assetid', element: <AddAsset /> },
          { path: 'assign-assets', element: <AssignAssets /> }
        ]
      },

      { path: 'support', element: <ManageSupport /> },
      {
        path: 'support', children: [
          { path: 'detail/:supportid', element: <ManageSupportDetails /> },
          { path: 'add', element: <AddSupportManagement /> }
        ]
      },

      { path: 'property-expense', element: <ManagePropertyExp /> },
      {
        path: 'property-expense', children: [
          { path: 'add', element: <AddPropertyExp /> },
          { path: 'update/:id', element: <AddPropertyExp /> },
        ]
      },

      { path: 'property-audit', element: <ManageAudit /> },
      {
        path: 'property-audit', children: [
          { path: 'add', element: <AddAudit /> },
          { path: 'update/:id', element: <UpdateAudit /> },
        ]
      },

      { path: 'dashboard', element: <DashboardPage /> },
      { path: 'user-dashboard', element: <UserDashboardPage /> },
      { path: 'my-profile', element: <ProfilePage /> },
      { path: 'change-password', element: <ChangePassword /> },
      // { path: 'flat-report', element: <ManageReportsFlats/> },
      // { path: 'vacant-flat-report/:status', element: <ManageReportsFlats/> }

      {
        path: 'reports', children: [
          { path: 'flat-report', element: <ManageReportsFlats /> },
          { path: 'rent-report', element: <ManageReportRent /> },
          { path: 'maintainence-report', element: <ManageReportMaintainence /> },
          { path: 'utility-report', element: <ManageReportUtlity /> },
          { path: 'other-report', element: <ManageReportOthers /> },
          { path: 'flat-report/:status', element: <ManageReportsFlats /> },
          { path: 'property-listing-report', element: <ManagePropertyListingReport /> },
          { path: 'property-expense-report', element:<ManageReportsPropertyExpense/>}
        ]
      }
    ]
  },
  {
    path: '/auth',
    element: (
      <PublicRoute>
        <AuthenticationLayout />
      </PublicRoute>
    ),
    children: [
      { path: 'signup', element: <SignUpContent /> },
      { path: 'signin', element: <SignInContent /> },
      { path: 'lock-screen', element: <LockScreenContent /> },
      { path: 'logout', element: <LogOutContent /> },
      { path: 'password-change', element: <PasswordChangeContent /> },
      { path: 'password-reset', element: <PasswordResetContent /> },
      { path: 'success-message', element: <SuccessMsgContent /> },
      { path: 'verification', element: <TwoStepAuthContent /> },
    ]
  },

  {
    path: '/500-error-page',
    element: <ErrorPageContentOne />
  },
  { path: '404-error-page', element: <ErrorPageContent /> },
  {
    path: '/offline',
    element: <OfflinePageContent />
  },
  {
    path: 'newtrial',
    element: <Newtrial />
  },
];

export default routes;
