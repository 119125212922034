import React, { useState, useEffect } from 'react';
import { fetchDashboardCardStats } from '../../services/dashboardService';
import useCounterAnimation from '../../hooks/useCounterAnimation';
import { Link } from 'react-router-dom';

export const DashboardBasicDetails = () => {
    const [dashboardStats, setDashboardStats] = useState({
        totalTenants: 0,
        activeTenants: 0,
        totalAmount: 0,
        currentMonthAmount: 0,
        availableFlats: 0,
        upcomingExpiry: 0,
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetchDashboardCardStats();
                const data = response.result;

                setDashboardStats({
                    totalTenants: data.totalTenants,
                    activeTenants: data.activeTenants,
                    totalAmount: data.totalAmount,
                    currentMonthAmount: data.currentMonthAmount,
                    availableFlats:data.availableFlats,
                    upcomingExpiry:data.upcomingExpiry,
                });
            } catch (error) {
                console.error('Error fetching dashboard stats:', error);
            }
        };

        fetchData();
    }, []);

    const tenantCount = useCounterAnimation(dashboardStats.totalTenants, 2000);
    const activeTenants = useCounterAnimation(dashboardStats.activeTenants, 2000);
    const totalAmount = useCounterAnimation(dashboardStats.totalAmount, 2000);
    const currentMonthAmount = useCounterAnimation(dashboardStats.currentMonthAmount, 2000);
    const availableFlats = useCounterAnimation(dashboardStats.availableFlats, 2000);
    const upcomingExpiry = useCounterAnimation(dashboardStats.upcomingExpiry, 2000);

    const currentMonth = new Date().toLocaleString('default', { month: 'long' });
    const vacantstatus = 'Vacant';

    return (
        <div className="row">
            <div className="col-xl-3 col-md-6">
                <div className="card card-animate">
                    <div className="card-body">
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                    Registered Tenants
                                </p>
                            </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-4">
                            <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                    {tenantCount}
                                </h4>
                                <a href="/onboarding-customers" className="text-decoration-underline">
                                    View number of tenants
                                </a>
                            </div>
                            <div className="avatar-sm flex-shrink-0">
                                <span className="avatar-title bg-success rounded fs-3">
                                    <i className="bx bx-group"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            

            <div className="col-xl-3 col-md-6">
                <div className="card card-animate">
                    <div className="card-body">
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                    Active Tenants
                                </p>
                            </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-4">
                            <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                    {activeTenants}
                                </h4>
                                <a href="/active-onboarding-customers" className="text-decoration-underline">
                                    View active tenants
                                </a>
                            </div>
                            <div className="avatar-sm flex-shrink-0">
                                <span className="avatar-title bg-info rounded fs-3">
                                    <i className="bx bx-group"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-xl-3 col-md-6">
                <div className="card card-animate">
                    <div className="card-body">
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                    Total Amount
                                </p>
                            </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-4">
                            <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                    ₹{Math.floor(totalAmount).toLocaleString()}
                                </h4>
                                <a href="/orders" className="text-decoration-underline">
                                    View amount details
                                </a>
                            </div>
                            <div className="avatar-sm flex-shrink-0">
                                <span className="avatar-title bg-warning rounded fs-3">
                                    <i className="bx bx-money"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-md-6">
                <div className="card card-animate">
                    <div className="card-body">
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                    Current Month - Total Amt
                                </p>
                            </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-4">
                            <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                    ₹{Math.floor(currentMonthAmount).toLocaleString()}
                                </h4>
                                <Link
                                    to={{
                                        pathname: `/current-month-orders/${currentMonth}`,
                                    }}
                                    className="text-decoration-underline"
                                >
                                    View current month amount
                                </Link>
                            </div>
                            <div className="avatar-sm flex-shrink-0">
                                <span className="avatar-title bg-danger rounded fs-3">
                                    <i className="bx bx-money"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-md-6">
                <div className="card card-animate">
                    <div className="card-body">
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                    Vacant Flats
                                </p>
                            </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-4">
                            <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                    {availableFlats}
                                </h4>
                                <Link
                                    to={{
                                        pathname: `/reports/flat-report/${vacantstatus}`,
                                    }}
                                    className="text-decoration-underline"
                                >
                                    View Vacant flats
                                </Link>
                            </div>
                            <div className="avatar-sm flex-shrink-0">
                                <span className="avatar-title bg-primary rounded fs-3">
                                    <i className="bx bx-building"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-md-6">
                <div className="card card-animate">
                    <div className="card-body">
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1 overflow-hidden">
                                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                                    Upcoming/Expiry Flats
                                </p>
                            </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-4">
                            <div>
                                <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                                    {upcomingExpiry}
                                </h4>
                                <a href="#upcoming-expiry-section" className="text-decoration-underline">
                                    View Upcoming flats
                                </a>
                            </div>
                            <div className="avatar-sm flex-shrink-0">
                                <span className="avatar-title bg-dark rounded fs-3">
                                    <i className="bx bx-building"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );
};

export default DashboardBasicDetails;
