import React from 'react';
import { Link } from 'react-router-dom';

export const OrderInfoDetails = ({ customerData }) => {

    const formatDate = (dateString) => {
        if (!dateString) return '-';
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-GB', {
            day: 'numeric', month: 'short', year: 'numeric',
            hour: '2-digit', minute: '2-digit', hour12: false
        }).format(date);
    };

    const OrderFields = [
        { label: 'Order Id', value: customerData.orderId || '-' },
        { label: 'Order Amount', value: `Rs. ${customerData.orderAmount || '-'}` },
        { label: 'Total Amount', value: `Rs. ${customerData.totalAmount || '-'}` },
        { label: 'Paid Amount', value: `Rs. ${customerData.paidAmount || '-'}` },
        { label: 'Balance Amount', value: `Rs. ${customerData.balanceAmount || '-'}` },
        { label: 'Order Status', value: customerData.orderStatus || '-' },
        { label: 'Order Payment Id', value: customerData.orderPaymentId || '-' },
        { label: 'Pay Type', value: customerData.payType || '-' },
        { label: 'Invoice Id', value: customerData.invoiceId || '-' },
        { label: 'Order Created On', value: formatDate(customerData.orderCreatedOn) || '-' },
    ];

    const PropertyFields = [

        { label: 'Customer Name', value: customerData.userName || '-' },
        { label: 'Email Address', value: customerData.emailAddress || '-' },
        { label: 'Contact Number', value: customerData.contactNo || '-' },
        { label: 'Move In Date', value: formatDate(customerData.moveInDate) || '-' },
        { label: 'Move Out Date', value: formatDate(customerData.moveOutDate) || '-' },
        { label: 'No of Guests', value: customerData.noOfGuest || '-' },
        { label: 'Stay Type', value: customerData.stayType || '-' },
        { label: 'Flat Name', value: customerData.flatName || '-' },
        { label: 'Flat Number', value: customerData.flatNo || '-' },
        { label: 'Property Name', value: customerData.propertyName || '-' },
    ];

    const renderOrderStatusBadge = (status) => {
        let badgeClass = '';
        if (status === 'Initiated') {
            badgeClass = 'badge-soft-secondary';
        } else if (status === 'Success') {
            badgeClass = 'badge-soft-success';
        } else if (status === 'Failed') {
            badgeClass = 'badge-soft-danger';
        } else if (status === 'Pending') {
            badgeClass = 'badge-soft-warning';
        }
        return <span className={`badge rounded-pill ${badgeClass}`}>{status}</span>;
    };

    return (
        <div className="card mt-xxl-n5">
            <div className="card-header order-info-details">
                <h5 className="mb-sm-1 mt-sm-1">Order Details </h5>
                <Link to={`/onboarding-customers/detail/${customerData.userGuid}`}
                    style={{ textDecoration: 'none' }}>
                    <button className="btn btn-secondary">Customer Details</button>
                </Link>
            </div>
            <div className="card-body p-4">
                <div className="row justify-content-center">
                    <div className="col-xxl-12">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="row g-3">
                                    {OrderFields.map((field, index) => (
                                        <div className="col-lg-3 col-6 mb-3" key={index}>
                                            <p className="mb-2 fw-semibold">{field.label}</p>
                                            <h5 className="fs-14 mb-0">
                                                <span id="invoice-no" className='sub-title-detail'>
                                                    {field.label === 'Order Status' ? renderOrderStatusBadge(field.value) : field.value}
                                                </span>
                                            </h5>
                                        </div>
                                    ))}
                                </div>
                                <div className='profile-info-fields'>
                                    <h5 className="mb-sm-1 mt-sm-1">Customer Details</h5>
                                </div>
                                <div className="row g-3 pt-4">
                                    {PropertyFields.map((field, index) => (
                                        <div className="col-lg-3 col-6 mb-3" key={index}>
                                            <p className="mb-2 fw-semibold">{field.label}</p>
                                            <h5 className="fs-14 mb-0">
                                                <span id="invoice-no" className='sub-title-detail'>
                                                    {field.label === 'Order Status' ? renderOrderStatusBadge(field.value) : field.value}
                                                </span>
                                            </h5>
                                        </div>
                                    ))}
                                    <div className="col-lg-8 col-6 mb-3">
                                        <p className="mb-2 fw-semibold">Property Address</p>
                                        < h5 className="fs-14 mb-0">
                                            <span id="invoice-no" className='sub-title-detail'>
                                                {customerData.propertyAddress || '-'}
                                            </span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};