import React, { useState, useEffect } from 'react';
import { createSWKProperty } from '../../../services/SWKPropertService';
import { validateFields } from '../../../utils/validation';
import { fetchCities } from '../../../services/cityService';
import { fetchLocations } from '../../../services/locationService';
import { fetchPropertyTypes } from '../../../services/propertyTypeService';
import "react-toastify/dist/ReactToastify.css";
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import "flatpickr/dist/flatpickr.min.css";
import Flatpickr from "react-flatpickr";
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { handleErrors } from '../../../utils/errorHandler';
import ComponentHeader from '../../Common/OtherElements/ComponentHeader';
import { MapSection } from './MapSection';
import AsyncSelect from 'react-select/async';
import { usePageLevelAccess } from '../../../hooks/usePageLevelAccess';
import { useNavigate } from 'react-router-dom';

const powerBackupOptions = ["DG", "UPS"];
const waterSourceOptions = ["Kaveri", "Borewell"];
export const AddSWRProperty = () => {
  const [cities, setCities] = useState([]);
  const [locations, setLocations] = useState([]);
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [showMapModal, setShowMapModal] = useState(false);
  const [data, setData] = useState({
    city: '',
    propertyLocation: '',
    propertyType: '',
    propertySeries: '',
    propertyName: '',
    noOfFlats: '',
    noOfFloors: '',
    buldingAge: '',
    powerBackup: '',
    waterSource: '',
    liftAvailble: 'no',
    eBillGenerateDate: '',
    rentStatDate:'',
    handOverDate:'',
    renewalDate:'',
    eBillDueDate: '',
    propertyLat: '',
    propertyLong: '',
    propertyAddress: '',
    mapUrl: '',
    propertyNote: ''
  });

  const [errors, setErrors] = useState({});
  const PageLevelAccessurl = 'property/add';
  const navigate = useNavigate();
  const { pageAccessData} = usePageLevelAccess(PageLevelAccessurl);

  useEffect(() => {
    if (pageAccessData) {
      if (!pageAccessData.addAccess) {
        navigate('/404-error-page');
      } else {
        return;
      }

    } else {
      console.log('No page access details found');
    }
  })

  useEffect(() => {
    const loadCities = async () => {
      try {
        const cityData = await fetchCities();
        setCities(cityData.result || []);
      } catch (error) {
        handleErrors(error);
      }
    };
    loadCities();
  }, []);

  useEffect(() => {
    const loadLocations = async () => {
      if (selectedCity) {
        try {
          const locationData = await fetchLocations();
          const filteredLocations = locationData.result.filter(location => location.cityName === selectedCity);
          setLocations(filteredLocations);
        } catch (error) {
          handleErrors(error);
        }
      } else {
        setLocations([]);
      }
    };
    loadLocations();
  }, [selectedCity]);

  useEffect(() => {
    const loadPropertyTypes = async () => {
      try {
        const propertyTypeData = await fetchPropertyTypes();
        setPropertyTypes(propertyTypeData.result || []);
      } catch (error) {
        handleErrors(error);
      }
    };
    loadPropertyTypes();
  }, []);

  const loadCityOptions = (inputValue, callback) => {
    const filteredCities = cities.filter((city) =>
      city.cityName.toLowerCase().includes(inputValue.toLowerCase())
    );

    setTimeout(() => {
      callback(
        filteredCities.map((city) => ({
          label: city.cityName,
          value: city.cityName,
        }))
      );
    }, 1000);
  };

  const loadLocationOptions = (inputValue, callback) => {
    const filteredLocations = locations.filter((location) =>
      location.locationName.toLowerCase().includes(inputValue.toLowerCase())
    );

    setTimeout(() => {
      callback(
        filteredLocations.map((location) => ({
          label: location.locationName,
          value: location.locationName,
        }))
      );
    }, 1000);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'powerBackup' || name === 'waterSource') {
      const checked = e.target.checked;
      const currentValue = data[name] || '';
      const values = currentValue.split(',');
      if (checked) {
        if (!values.includes(value)) {
          values.push(value);
        }
      } else {
        const index = values.indexOf(value);
        if (index !== -1) {
          values.splice(index, 1);
        }
      }
      setData((prevData) => ({
        ...prevData,
        [name]: values.join(','),
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const { valid, errors } = validateFields(data);
    setErrors(errors);

    if (valid) {
      try {
        setIsButtonDisabled(true);
        await createSWKProperty(data);
        toast.success('Property added successfully!');
        setIsButtonDisabled(false);
        setData({ city: '', propertyLocation: '', propertyType: '', propertySeries: '', propertyName: '', noOfFlats: '', noOfFloors: '', buldingAge: '', powerBackup: '', waterSource: '', liftAvailble: '', eBillGenerateDate: '', eBillDueDate: '', propertyLat: '', propertyLong: '', propertyAddress: '', mapUrl: '', propertyNote: '',handOverDate:'',rentStatDate:'',renewalDate:'' });
      } catch (error) {
        handleErrors(error);
        setIsButtonDisabled(false);
      }
    }
  };

  const handleMapModalClose = () => setShowMapModal(false);
  const handleMapModalOpen = () => setShowMapModal(true);

  const handleAddressSelection = (address) => {
    setData({ ...data, propertyAddress: address });
    setShowMapModal(false);
  };

  const handleLatChange = (lat) => {
    setData((prevData) => ({ ...prevData, propertyLat: lat }));
  };

  const handleMapUrlChange = (address) => {
    setData((prevData) => ({ ...prevData, mapUrl: address }));
  };

  const handleLngChange = (lng) => {
    setData((prevData) => ({ ...prevData, propertyLong: lng }));
  };

  const handleCheckboxChange = (field, option) => {
    const currentSelections = data[field] ? data[field].split(", ") : [];

    if (currentSelections.includes(option)) {
      const updatedSelections = currentSelections.filter(item => item !== option);
      setData({
        ...data,
        [field]: updatedSelections.join(", ")
      });
    } else {
      currentSelections.push(option);
      setData({
        ...data,
        [field]: currentSelections.join(", ")
      });
    }
  };

  return (
    <>
      <ComponentHeader title='Property' />
      <div className="row">
        <div className="col-xxl-12">
          <div className="card mt-xxl-n5">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h5 className="mb-sm-1 mt-sm-1">Add Properties</h5>

            </div>
            <div className="card-body p-4">
              <form onSubmit={handleSubmit} method="POST">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="city" className="form-label">City <span className='required-field'>*</span></label>
                      <AsyncSelect
                        cacheOptions
                        loadOptions={loadCityOptions}
                        defaultOptions={cities.map((city) => ({
                          label: city.cityName,
                          value: city.cityName,
                        }))}
                        onChange={(selectedOption) => {
                          setSelectedCity(selectedOption?.value || '');
                          handleInputChange({
                            target: {
                              name: 'city',
                              value: selectedOption?.value || '',
                            },
                          });
                        }}
                        value={{
                          label: cities.find((city) => city.cityName === data.city)?.cityName,
                          value: data.city,
                        }}
                        isClearable
                        placeholder="Select City"
                        className={errors.city ? 'is-invalid' : ''}
                      />
                      {errors.city && <div className="invalid-feedback">{errors.city}</div>}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="propertyLocation" className="form-label">Property Location <span className='required-field'>*</span></label>
                      <AsyncSelect
                        cacheOptions
                        loadOptions={loadLocationOptions}
                        defaultOptions={locations.map((location) => ({
                          label: location.locationName,
                          value: location.locationName,
                        }))}
                        onChange={(selectedOption) => {
                          handleInputChange({
                            target: {
                              name: 'propertyLocation',
                              value: selectedOption?.value || '',
                            },
                          });
                        }}
                        value={{
                          label: locations.find(
                            (location) => location.locationName === data.propertyLocation
                          )?.locationName,
                          value: data.propertyLocation,
                        }}
                        isClearable
                        placeholder="Select Location"
                        className={errors.propertyLocation ? 'is-invalid' : ''}
                      />
                      {errors.propertyLocation && <div className="invalid-feedback">{errors.propertyLocation}</div>}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="propertyType" className="form-label">Property Type <span className='required-field'>*</span></label>
                      <select
                        name="propertyType"
                        className={`form-select ${errors.propertyType ? 'is-invalid' : ''}`}
                        value={data.propertyType}
                        onChange={handleInputChange}
                      >
                        <option value="" disabled>Select Property Type</option>
                        {propertyTypes.map((type) => (
                          <option key={type.id} value={type.propertyType}>{type.propertyType}</option>
                        ))}
                      </select>
                      {errors.propertyType && <div className="invalid-feedback">{errors.propertyType}</div>}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="propertySeries" className="form-label">Property Series</label>
                      <input
                        type="number"
                        name="propertySeries"
                        className={`form-control ${errors.propertySeries ? 'is-invalid' : ''}`}
                        value={data.propertySeries}
                        onChange={handleInputChange}
                        placeholder='Enter Property Series'
                      />
                      {errors.propertySeries && <div className="invalid-feedback">{errors.propertySeries}</div>}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="propertyName" className="form-label">Property Name <span className='required-field'>*</span></label>
                      <input
                        type="text"
                        name="propertyName"
                        className={`form-control ${errors.propertyName ? 'is-invalid' : ''}`}
                        value={data.propertyName}
                        onChange={handleInputChange}
                        placeholder='Enter Property Name'
                      />
                      {errors.propertyName && <div className="invalid-feedback">{errors.propertyName}</div>}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="noOfFlats" className="form-label">No. of Flats <span className='required-field'>*</span></label>
                      <input
                        type="number"
                        name="noOfFlats"
                        className={`form-control ${errors.noOfFlats ? 'is-invalid' : ''}`}
                        value={data.noOfFlats}
                        onChange={handleInputChange}
                        placeholder='Enter Number of Flats'
                      />
                      {errors.noOfFlats && <div className="invalid-feedback">{errors.noOfFlats}</div>}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="noOfFloors" className="form-label">No. of Floors <span className='required-field'>*</span></label>
                      <input
                        type="number"
                        name="noOfFloors"
                        className={`form-control ${errors.noOfFloors ? 'is-invalid' : ''}`}
                        value={data.noOfFloors}
                        onChange={handleInputChange}
                        placeholder='Enter Number of Floors'
                      />
                      {errors.noOfFloors && <div className="invalid-feedback">{errors.noOfFloors}</div>}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="buldingAge" className="form-label">Building Age <span className='required-field'>*</span></label>
                      <input
                        type="number"
                        name="buldingAge"
                        className={`form-control ${errors.buldingAge ? 'is-invalid' : ''}`}
                        value={data.buldingAge}
                        onChange={handleInputChange}
                        placeholder='Enter Building Age'
                      />
                      {errors.buldingAge && <div className="invalid-feedback">{errors.buldingAge}</div>}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="powerBackup" className="form-label">Power Backup</label>
                      <FormControl
                        sx={{
                          m: 1,
                          width: '100%',
                        }}
                        margin="normal"
                        fullWidth
                      >
                        <Select
                          labelId="power-backup-checkbox-label"
                          className='form-select'
                          id="power-backup-checkbox"
                          multiple
                          value={data.powerBackup ? data.powerBackup.split(',') : []}
                          onChange={handleChange}
                          input={<OutlinedInput label="Power Backup" />}
                          renderValue={(selected) => selected.join(', ')}
                        >
                          {powerBackupOptions.map((option) => (
                            <MenuItem
                              key={option}
                              value={option}
                              onClick={(e) => {
                                if (e.target.tagName !== 'INPUT') {
                                  handleCheckboxChange('powerBackup', option);
                                }
                              }}
                            >
                              <Checkbox
                                checked={data.powerBackup.includes(option)}
                                onChange={() => handleCheckboxChange('powerBackup', option)}
                              />
                              <ListItemText primary={option} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      {errors.powerBackup && <div className="invalid-feedback">{errors.powerBackup}</div>}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="waterSource" className="form-label">Water Source</label>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        sx={{
                          m: 1,
                          width: '100%',
                        }}
                      >
                        <Select
                          labelId="water-source-checkbox-label"
                          className='form-select'
                          id="water-source-checkbox"
                          multiple
                          value={data.waterSource ? data.waterSource.split(',') : []}
                          onChange={handleChange}
                          input={<OutlinedInput label="Water Source" />}
                          renderValue={(selected) => selected.join(', ')}
                        >
                          {waterSourceOptions.map((option) => (
                            <MenuItem
                              key={option}
                              value={option}
                              onClick={(e) => {
                                if (e.target.tagName !== 'INPUT') {
                                  handleCheckboxChange('waterSource', option);
                                }
                              }}
                            >
                              <Checkbox
                                checked={data.waterSource.includes(option)}
                                onChange={() => handleCheckboxChange('waterSource', option)}
                              />
                              <ListItemText primary={option} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      {errors.waterSource && <div className="invalid-feedback">{errors.waterSource}</div>}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="eBillGenerateDate" className="form-label">Electricity bill generation date (1-31)</label>
                      <input
                        type="number"
                        name="eBillGenerateDate"
                        className={`form-control ${errors.eBillGenerateDate ? 'is-invalid' : ''}`}
                        value={data.eBillGenerateDate}
                        onChange={handleInputChange}
                        placeholder='Enter Electricity bill generation date'
                      />
                      {errors.eBillGenerateDate && <div className="invalid-feedback">{errors.eBillGenerateDate}</div>}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="eBillDueDate" className="form-label">Electricity bill due date (1-31)</label>
                      <input
                        type="number"
                        name="eBillDueDate"
                        className={`form-control ${errors.eBillDueDate ? 'is-invalid' : ''}`}
                        value={data.eBillDueDate}
                        onChange={handleInputChange}
                        placeholder='Enter Electricity bill due date'
                      />
                      {errors.eBillDueDate && <div className="invalid-feedback">{errors.eBillDueDate}</div>}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="propertyLat" className="form-label">Property Latitude</label>
                      <input
                        type="number"
                        name="propertyLat"
                        className={`form-control ${errors.propertyLat ? 'is-invalid' : ''}`}
                        value={data.propertyLat}
                        onChange={handleInputChange}
                        placeholder='Enter Property Latitude'
                      />
                      {errors.propertyLat && <div className="invalid-feedback">{errors.propertyLat}</div>}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="propertyLong" className="form-label">Property Longitude</label>
                      <input
                        type="number"
                        name="propertyLong"
                        className={`form-control ${errors.propertyLong ? 'is-invalid' : ''}`}
                        value={data.propertyLong}
                        onChange={handleInputChange}
                        placeholder='Enter Property Longitude'
                      />
                      {errors.propertyLong && <div className="invalid-feedback">{errors.propertyLong}</div>}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="handOverDate" className="form-label">Hand Over Date</label>
                      <Flatpickr
                        className={`form-control ${errors.handOverDate ? 'is-invalid' : ''}`}
                        value={data.handOverDate ? new Date(data.handOverDate) : null}
                        placeholder='Enter Hand Over Date'
                        onChange={(date) => setData({ ...data, handOverDate: date.length ? date[0].toISOString() : '' })}
                        options={{
                          dateFormat: "d-M-Y",
                          monthSelectorType: 'static',
                        }}
                      />
                      {errors.handOverDate && <div className="invalid-feedback">{errors.handOverDate}</div>}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="rentStatDate" className="form-label">Rent Start Date</label>
                      <Flatpickr
                        className={`form-control ${errors.rentStatDate ? 'is-invalid' : ''}`}
                        value={data.rentStatDate ? new Date(data.rentStatDate) : null}
                        placeholder='Enter Hand Over Date'
                        onChange={(date) => setData({ ...data, rentStatDate: date.length ? date[0].toISOString() : '' })}
                        options={{
                          dateFormat: "d-M-Y",
                          monthSelectorType: 'static',
                        }}
                      />
                      {errors.rentStatDate && <div className="invalid-feedback">{errors.rentStatDate}</div>}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="renewalDate" className="form-label">Renewal Date</label>
                      <Flatpickr
                        className={`form-control ${errors.renewalDate ? 'is-invalid' : ''}`}
                        value={data.renewalDate ? new Date(data.renewalDate) : null}
                        placeholder='Enter Hand Over Date'
                        onChange={(date) => setData({ ...data, renewalDate: date.length ? date[0].toISOString() : '' })}
                        options={{
                          dateFormat: "d-M-Y",
                          monthSelectorType: 'static',
                        }}
                      />
                      {errors.renewalDate && <div className="invalid-feedback">{errors.renewalDate}</div>}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="mapUrl" className="form-label">Map URL (Note : Please provide embed Link)</label>
                      <input
                        type="url"
                        name="mapUrl"
                        className={`form-control ${errors.mapUrl ? 'is-invalid' : ''}`}
                        value={data.mapUrl}
                        onChange={handleInputChange}
                        placeholder='Enter Map URL'
                      />
                      {errors.mapUrl && <div className="invalid-feedback">{errors.mapUrl}</div>}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3 d-flex align-items-center pt-4">
                      <label htmlFor="liftAvailble" className="form-label pe-3">Lift Available</label>
                      <div className="form-check form-switch form-switch-custom form-switch-primary">

                        <input
                          className={`form-check-input ${errors.liftAvailble ? 'is-invalid' : ''}`}
                          type="checkbox"
                          id="liftAvailble"
                          name="liftAvailble"
                          checked={data.liftAvailble === 'yes'}
                          onChange={(e) =>
                            setData((prevData) => ({
                              ...prevData,
                              liftAvailble: e.target.checked ? 'yes' : 'no',
                            }))
                          }
                        />
                        <label className="form-check-label" htmlFor="liftAvailble">
                          {data.liftAvailble === 'yes' ? 'Yes' : 'No'}
                        </label>
                        {errors.liftAvailble && <div className="invalid-feedback">{errors.liftAvailble}</div>}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="propertyAddress" className="form-label">Property Address <span className='required-field'>*</span></label>
                      <textarea
                        type="text"
                        name="propertyAddress"
                        className={`form-control ${errors.propertyAddress ? 'is-invalid' : ''}`}
                        value={data.propertyAddress}
                        onChange={handleInputChange}
                        placeholder='Enter Property Address'
                      />
                      <span href="#" onClick={handleMapModalOpen} style={{ color: 'blue', cursor: 'pointer' }}>Select Location on Map</span>
                      {errors.propertyAddress && <div className="invalid-feedback">{errors.propertyAddress}</div>}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="propertyNote" className="form-label">Property Note <span className='required-field'>*</span></label>
                      <textarea
                        type="text"
                        name="propertyNote"
                        className={`form-control ${errors.propertyNote ? 'is-invalid' : ''}`}
                        value={data.propertyNote}
                        onChange={handleInputChange}
                        placeholder='Enter Property Note'
                      />

                      {errors.propertyNote && <div className="invalid-feedback">{errors.propertyNote}</div>}
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="pt-4">
                      <button type="submit" className="btn btn-secondary pt-1 pb-1" disabled={isButtonDisabled}>{isButtonDisabled ? 'Saving' : 'Save'}</button>
                    </div>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showMapModal} onHide={handleMapModalClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Select Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MapSection
            onAddressSelect={handleAddressSelection}
            onLatChange={handleLatChange}
            onLngChange={handleLngChange}
            onMapUrlChange={handleMapUrlChange}
            initialAddress={data.propertyAddress}
            initialLat={data.propertyLat}
            initiallong={data.propertyLong}
            initialMapUrl={data.mapUrl}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
