import React, { useState, useEffect } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import "flatpickr/dist/flatpickr.min.css";
import Flatpickr from "react-flatpickr";
import { useNavigate, useParams } from 'react-router-dom';
import { validatePersonalDetails } from './../../utils/validation';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { handleErrors } from '../../utils/errorHandler';
import { addonboardingCustomers, fetchOnboardingCustomersById, updateOnboardingCustomers } from './../../services/onboardingCustomerService';
import { Link } from 'react-router-dom';
import { usePageLevelAccess } from '../../hooks/usePageLevelAccess';

export const PersonalDetails = () => {
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const { customerGuid } = useParams();
    const navigate = useNavigate();
    const [values, setValues] = useState({
        firstName: '', lastName: '', gender: '', emailAddress: '', phoneNo: '', dob: '', whatsApp: '', altEmail: '', altPhoneNo: '', nationality: '', streetAddress: '', city: '', state: '', country: '', pincode: ''
    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [PageLevelAccessurl, setPageLevelAccessurl] =useState();
    useEffect(() => {
        if(customerGuid){
            setPageLevelAccessurl('onboarding-customers/update/:customerGuid');
        }else{
            setPageLevelAccessurl('onboarding-customers/add');
        }
    },[customerGuid])
    const { pageAccessData } = usePageLevelAccess(PageLevelAccessurl);

    useEffect(() => {
      if (pageAccessData) {
        if(customerGuid){
            if (!pageAccessData.editAccess) {
                navigate('/404-error-page');
            } else {
                return;
            }
        } else{
            if (!pageAccessData.addAccess) {
                navigate('/404-error-page');
            } else {
                return;
            }
        }

      } else {
          console.log('No page access details found');
      }
  })

    const today = new Date();
    const eighteenYearsAgo = new Date(
        today.getFullYear() - 18,
        today.getMonth(),
        today.getDate()
    );

    useEffect(() => {
        if (customerGuid) {
            setIsDisabled(true);
            const fetchData = async () => {
                try {
                    const apidata = await fetchOnboardingCustomersById(customerGuid);
                    const data = apidata.result;
                    setValues({
                        firstName: data.firstName || '',
                        lastName: data.lastName || '',
                        gender: data.gender || '',
                        emailAddress: data.emailAddress || '',
                        phoneNo: data.phoneNo || '',
                        dob: data.dob ? [new Date(data.dob)] : '',
                        whatsApp: data.whatsApp || '',
                        altEmail: data.altEmail || '',
                        altPhoneNo: data.altPhoneNo || '',
                        nationality: data.nationality || '',
                        streetAddress: data.streetAddress || '',
                        city: data.city || '',
                        state: data.state || '',
                        country: data.country || '',
                        pincode: data.pincode || ''
                    });
                } catch (error) {
                    console.error("Error fetching customer data:", error);
                }
            };
            fetchData();
        } else {
            setValues({
                firstName: '',
                lastName: '',
                gender: '',
                emailAddress: '',
                phoneNo: '',
                dob: '',
                whatsApp: '',
                altEmail: '',
                altPhoneNo: '',
                nationality: '',
                streetAddress: '',
                city: '',
                state: '',
                country: '',
                pincode: ''
            });
            setIsDisabled(false);
        }
    }, [customerGuid]);

    const handleChange = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        });
    };

    const handlePhoneChange = (phone) => setValues({ ...values, phoneNo: phone });
    const handleWhatsAppChange = (phone) => setValues({ ...values, whatsApp: phone });
    const handleAltPhoneChange = (phone) => setValues({ ...values, altPhoneNo: phone });

    const handleSubmit = async (e, action) => {
        e.preventDefault();
        const validationErrors = validatePersonalDetails(values);
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            setIsSubmitting(true);
            try {
                const formattedDob = values.dob ? values.dob[0].toISOString().split('T')[0] : null;
                const payload = {
                    userGuid: customerGuid,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    gender: values.gender,
                    dob: formattedDob,
                    altEmail: values.altEmail,
                    altPhoneNo: values.altPhoneNo,
                    nationality: values.nationality,
                    streetAddress: values.streetAddress,
                    city: values.city,
                    state: values.state,
                    country: values.country,
                    pincode: values.pincode
                };

                setIsButtonDisabled(true);
                let response;

                if (customerGuid) {
                    await updateOnboardingCustomers(payload);
                    toast.success("Customer details updated successfully!");
                    setIsButtonDisabled(false);
                } else {
                    response = await addonboardingCustomers({ ...values, dob: formattedDob });
                    toast.success("Customer onboarding successful!");
                    setValues({
                        firstName: '',lastName: '',gender: '',emailAddress: '',phoneNo: '',dob: '',whatsApp: '',altEmail: '',
                        altPhoneNo: '',nationality: '',streetAddress: '',city: '',state: '',country: '',pincode: ''
                    });
                    setIsButtonDisabled(false);
                }

                if (action === 'addOrders') {
                    navigate(`/onboarding-customers/add-order/${response.cust_id}`);
                } else {
                    console.log('updation successful');
                }
            } catch (error) {
                handleErrors(error);
                setIsButtonDisabled(false);
            } finally {
                setIsButtonDisabled(false);
                setIsSubmitting(false);
            }
        }
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">Onboard Customer Details</h4>
                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">
                                    <Link to="/">
                                        <i className="ri-home-2-fill"></i>
                                    </Link>
                                </li>
                                {customerGuid ? (
                                    <>
                                     <li className="breadcrumb-item">
                                    <Link to='/onboarding-customers'>
                                    Manage Onboard Customers
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">
                                    Update Onboard Customer
                                </li>
                                    </>
                                ) :(
                                    <>
                                    <li className="breadcrumb-item">
                                    Add Onboard Customer
                                </li>
                                    </>
                                )}
                               
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-xxl-8">
                        <div className="card mt-xxl-n5">
                            <div className="card-header">
                                <h5 className="mb-sm-1 mt-sm-1">Onboard Customer Details</h5>
                            </div>
                            <div className="card-body p-4">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="firstName" className="form-label">First Name <span className='required-field'>*</span></label>
                                            <input
                                                type="text"
                                                name="firstName"
                                                value={values.firstName}
                                                placeholder='Enter First Name'
                                                onChange={handleChange}
                                                className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
                                            />
                                            {errors.firstName && <p className="text-danger">{errors.firstName}</p>}
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="lastName" className="form-label">Last Name <span className='required-field'>*</span></label>
                                            <input
                                                type="text"
                                                name="lastName"
                                                value={values.lastName}
                                                placeholder='Enter Last Name'
                                                onChange={handleChange}
                                                className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
                                            />
                                            {errors.lastName && <p className="text-danger">{errors.lastName}</p>}
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="emailAddress" className="form-label">Email <span className='required-field'>*</span></label>
                                            <input
                                                type="email"
                                                name="emailAddress"
                                                value={values.emailAddress}
                                                placeholder='Enter Email Address'
                                                onChange={handleChange}
                                                className={`form-control ${errors.emailAddress ? 'is-invalid' : ''}`}
                                                disabled={isDisabled}
                                            />
                                            {errors.emailAddress && <p className="text-danger">{errors.emailAddress}</p>}
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="phonenumber" className="form-label">Phone Number <span className='required-field'>*</span></label>
                                            <PhoneInput
                                            international
                                                value={values.phoneNo}
                                                onChange={handlePhoneChange}
                                                disabled={isDisabled}
                                                defaultCountry='IN'
                                                maxLength="15"
                                                placeholder='Enter Phone Number'
                                            />
                                            {errors.phoneNo && <div style={{ color: '#dc3545' }}>{errors.phoneNo}</div>}
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="gender" className="form-label">Gender <span className='required-field'>*</span></label>
                                            <select
                                                className={`form-select ${errors.gender ? 'is-invalid' : ''}`}
                                                name="gender"
                                                value={values.gender}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select Gender</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                            </select>
                                            {errors.gender && <p className="text-danger">{errors.gender}</p>}
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="city" className="form-label">City <span className='required-field'>*</span></label>
                                            <input
                                                type="text"
                                                name="city"
                                                value={values.city}
                                                placeholder='Enter City'
                                                onChange={handleChange}
                                                className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                                            />
                                            {errors.city && <p className="text-danger">{errors.city}</p>}
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="state" className="form-label">State <span className='required-field'>*</span></label>
                                            <input
                                                type="text"
                                                name="state"
                                                value={values.state}
                                                placeholder='Enter State'
                                                onChange={handleChange}
                                                className={`form-control ${errors.state ? 'is-invalid' : ''}`}
                                            />
                                            {errors.state && <p className="text-danger">{errors.state}</p>}
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="country" className="form-label">Country <span className='required-field'>*</span></label>
                                            <input
                                                type="text"
                                                name="country"
                                                value={values.country}
                                                placeholder='Enter Country'
                                                onChange={handleChange}
                                                className={`form-control ${errors.country ? 'is-invalid' : ''}`}
                                            />
                                            {errors.country && <p className="text-danger">{errors.country}</p>}
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="pincode" className="form-label">Pincode <span className='required-field'>*</span></label>
                                            <input
                                                type="text"
                                                name="pincode"
                                                value={values.pincode}
                                                placeholder='Enter Pincode'
                                                onChange={handleChange}
                                                className={`form-control ${errors.pincode ? 'is-invalid' : ''}`}
                                            />
                                            {errors.pincode && <p className="text-danger">{errors.pincode}</p>}
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="mb-3">
                                            <label htmlFor="streetAddress" className="form-label">Street Address <span className='required-field'>*</span></label>
                                            <textarea
                                                name="streetAddress"
                                                value={values.streetAddress}
                                                placeholder='Enter Street Address'
                                                onChange={handleChange}
                                                className={`form-control ${errors.streetAddress ? 'is-invalid' : ''}`}
                                                rows="3"
                                            />
                                            {errors.streetAddress && <p className="text-danger">{errors.streetAddress}</p>}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col xxl-4">
                        <div className="card mt-xxl-n5">
                            <div className="card-body p-4">
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label htmlFor="dob" className="form-label">Date of Birth <span className='required-field'>*</span></label>
                                        <Flatpickr
                                            className={`form-control ${errors.dob ? 'is-invalid' : ''}`}
                                            value={values.dob}
                                            placeholder="Enter Date of Birth"
                                            onChange={(date) => setValues({ ...values, dob: date })}
                                            options={{
                                                dateFormat: "d-M-Y",
                                                monthSelectorType: "static",
                                                maxDate: eighteenYearsAgo,
                                            }}
                                        />
                                        {errors.dob && <p className="text-danger">{errors.dob}</p>}
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label htmlFor="altEmail" className="form-label">Alternate Email</label>
                                        <input
                                            type="email"
                                            name="altEmail"
                                            value={values.altEmail}
                                            placeholder='Enter Alternate Email Address'
                                            onChange={handleChange}
                                            className={`form-control ${errors.altEmail ? 'is-invalid' : ''}`}
                                        />
                                        {errors.altEmail && <p className="text-danger">{errors.altEmail}</p>}
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label htmlFor="whatsApp" className="form-label">WhatsApp Number</label>
                                        <PhoneInput
                                        international
                                            value={values.whatsApp}
                                            onChange={handleWhatsAppChange}
                                            disabled={isDisabled}
                                            placeholder='Enter whatsup Number'
                                            maxLength="15"
                                            defaultCountry='IN'
                                        />
                                        {errors.whatsApp && <p className="text-danger">{errors.whatsApp}</p>}
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label htmlFor="nationality" className="form-label">Nationality</label>
                                        <input
                                            type="text"
                                            name="nationality"
                                            value={values.nationality}
                                            placeholder='Enter Nationality'
                                            onChange={handleChange}
                                            className={`form-control ${errors.nationality ? 'is-invalid' : ''}`}
                                        />
                                        {errors.nationality && <p className="text-danger">{errors.nationality}</p>}
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label htmlFor="altPhoneNo" className="form-label">Alternate Phone Number</label>
                                        <PhoneInput
                                        international
                                            value={values.altPhoneNo}
                                            onChange={handleAltPhoneChange}
                                            defaultCountry='IN'
                                            maxLength="15"
                                            placeholder='Enter Alternative Phone Number'
                                        />
                                        {errors.altPhoneNo && <p className="text-danger">{errors.altPhoneNo}</p>}
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <button type="submit" className="btn btn-secondary" disabled={isButtonDisabled}>
                    {isSubmitting ? (customerGuid ? 'Updating' : 'Saving') : (customerGuid ? "Update" : "Save")}
                </button>
                {customerGuid ? '' : (
                    <button type="button" className="btn btn-success ms-3" onClick={(e) => handleSubmit(e, 'addOrders')}>
                        Save and Add Orders
                    </button>
                )
                }
            </form>
        </>
    );
};
