import React, { useState } from 'react';
import TableHeader from '../Common/TableComponent/TableHeader';
import { TableDataStatusError } from '../Common/OtherElements/TableDataStatusError';
import { Link } from 'react-router-dom';
import { PaymentUpdateModal } from './PaymentUpdateModal';



export const RentDetails = ({ customerData, handleFetchData }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedPaymentId, setSelectedPaymentId] = useState('');

    const handleOpenModal = (paymentId) => {
        setSelectedPaymentId(paymentId);
        setModalVisible(true);
    };

    const handleCloseModal = () => {
        setModalVisible(false);
        setSelectedPaymentId(null);
    };

    const rentdetails = customerData?.oRent || [];
    const formatDate = (dateString) => {
        if (!dateString) return '-';
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-GB', {
            day: 'numeric', month: 'short', year: 'numeric',
        }).format(date);
    };

    const handlePaymentSuccess = () => {
        handleFetchData();
    };

    return (
        <div className="card mt-xxl-n5">
            <div className="card-header">
                <h5 className="mb-sm-1 mt-sm-1">Rent Details</h5>
            </div>
            <div className="table-responsive">
                <table className="table table-nowrap align-middle table-bordered">
                    <TableHeader columns={['#', 'From Date', 'To Date', 'Rent Amount', 'Owner Rent', 'Rent for this month', 'Difference Without Tax', 'Tax Amount', 'Difference Amount', 'Payment Id', 'Payment Status', 'Paid On', 'Add Payment']} />
                    <tbody className="manage-page-group-table-values">
                        {rentdetails.length === 0 ? (
                            <TableDataStatusError colspan="12" />
                        ) : (
                            rentdetails.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{formatDate(item.fromDate)}</td>
                                    <td>{formatDate(item.toDate)}</td>
                                    <td>Rs. {item.rentAmount}</td>
                                    <td>Rs. {item.actualRent}</td>
                                    <td>Rs. {item.currentRentAmount}</td>
                                    <td>Rs. {item.differenceWithoutTax}</td>
                                    <td>Rs. {item.taxAmount}</td>
                                    <td>Rs. {item.differenceAmount}</td>
                                    <td>{item.paymentId}</td>
                                    <td>
                                        {item.paymentStatus === 'Initiated' && <span className="badge rounded-pill badge-soft-primary">Initiated</span>}
                                        {item.paymentStatus === 'Success' && <span className="badge rounded-pill badge-soft-success">Success</span>}
                                        {item.paymentStatus === 'Failed' && <span className="badge rounded-pill badge-soft-danger">Failed</span>}
                                        {item.paymentStatus === 'Pending' && <span className="badge rounded-pill badge-soft-warning">Pending</span>}
                                    </td>
                                    <td>{formatDate(item.paidOn)}</td>
                                    <td>
                                        {item.paymentStatus === 'Success' ? (
                                            <span style={{color:'green',fontWeight:'500'}}>
                                                No Payment Pending
                                            </span>
                                        ) : (
                                            <span className="badge text-bg-danger" style={{ fontSize: '11px' }}>
                                                <Link
                                                    to="#"
                                                    style={{ textDecoration: 'none', color: '#fff' }}
                                                    onClick={() => handleOpenModal(item.orderGuid)}
                                                >
                                                    Add Payment
                                                </Link>
                                            </span>
                                        )}

                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
            <PaymentUpdateModal show={modalVisible} onClose={handleCloseModal} orderId={selectedPaymentId} pageIdentify='rent' onPaymentSuccess={handlePaymentSuccess}/>
        </div>
    );
};
