import React from 'react';

export const ManageEachPropertyDetail = ({ propertyDetails }) => {
    const propertyFields = [
        { label: 'City', value: propertyDetails.city },
        { label: 'Location', value: propertyDetails.propertyLocation },
        { label: 'Property Type', value: propertyDetails.propertyType },
        { label: 'No Of Flats', value: propertyDetails.noOfFlats },
        { label: 'No Of Floors', value: propertyDetails.noOfFloors },
        { label: 'Building Age', value: propertyDetails.buldingAge },
        { label: 'Power Back Up', value: propertyDetails.powerBackup },
        { label: 'Lift Available', value: propertyDetails.liftAvailble },
        { label: 'Water Source', value: propertyDetails.waterSource },
        { label: 'Property Note', value:propertyDetails.propertyNote},
    ];

    return (
        <div className="row justify-content-center">
            <div className="col-xxl-12">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card-body p-4">
                            <div className="row g-3">
                                {propertyFields.map((field, index) => (
                                    <div className="col-lg-3 col-6 mb-3" key={index}>
                                        <p className="mb-2 fw-semibold">{field.label}</p>
                                        <h5 className="fs-14 mb-0">
                                            <span id="invoice-no" className='sub-title-detail'>
                                                {field.value}
                                            </span>
                                        </h5>
                                    </div>
                                ))}
                                <div className="col-lg-6">
                                    <p className="mb-2 fw-semibold">Property Address</p>
                                    <h5 className="fs-14 mb-0">
                                        <span id="invoice-no" className='sub-title-detail'>
                                            {propertyDetails.propertyAddress}
                                        </span>
                                    </h5>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
