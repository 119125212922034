import React, { useCallback, useEffect, useState, useRef } from "react";
import TableHeader from "../Common/TableComponent/TableHeader";
import TablesRow from "../Common/TableComponent/TablesRow";
import { Loading } from "../Common/OtherElements/Loading";
import {
  paginationFlatReport,
  fetchLocations,
} from "../../services/ReportsService";
import { TableDataStatusError } from "../Common/OtherElements/TableDataStatusError";
import { handleErrors } from "../../utils/errorHandler";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { Pagination } from "../Common/TableComponent/Pagination";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import ToggleFLatPublish from "./ToggleFLatPublish";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import AsyncSelect from 'react-select/async';
import * as XLSX from "xlsx";
import allImages from "../../assets/images-import";
import { usePageLevelAccess } from "../../hooks/usePageLevelAccess";
import { fetchSWKProperty } from '../../services/SWKPropertService';
import { useNavigate } from 'react-router-dom';


export const ManageFlatReports = () => {
  const { status } = useParams();
  const [entriesPerPage, setEntriesPerPage] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [propertieslist, setPropertieslist] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [flatStatus, setflatStatus] = useState(status);
  const [searchLocations, setSearchLocation] = useState([]);
  const [locVal, setLocVal] = useState("");
  const searchInputRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [pageAccessDetails, setPageAccessDetails] = useState([]);
  const navigate = useNavigate();
  const PageLevelAccessurl = 'reports/flat-report/';
  const { pageAccessData } = usePageLevelAccess(PageLevelAccessurl);
  const [downloadBtnDisable, setDownloadBtnDisable] = useState(false);

  useEffect(() => {
    if (pageAccessData) {
      if (!pageAccessData.viewAccess) {
        navigate('/404-error-page');
      } else {
        setPageAccessDetails(pageAccessData);
      }

    } else {
      console.log('No page access details found');
    }
  }, [pageAccessData, navigate])

  const fetchProperties = useCallback(async () => {
    setLoading(true);
    const formData = {
      pageSize: entriesPerPage,
      pageNo: currentPage,
      fromDate,
      toDate,
      sParam: selectedProperty,
      location: locVal,
      flatStatus: flatStatus,
    };
    try {
      const response = await paginationFlatReport(formData);
      const { data } = response;
      setProperties(data.result);
      setTotalCount(data.result[0]?.totalCount || 0);
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  }, [
    entriesPerPage,
    currentPage,
    fromDate,
    toDate,
    selectedProperty,
    locVal,
    flatStatus,
  ]);

  useEffect(() => {
    fetchProperties();
  }, [fetchProperties]);

  const handleDownloadReport = async () => {
    const formData = {
      pageSize: totalCount,
      pageNo: 1,
      fromDate,
      toDate,
    };

    try {
      setDownloadBtnDisable(true);
      const response = await paginationFlatReport(formData);
      const { data } = response;
      const allProperties = data.result;
      setDownloadBtnDisable(false);
      const excelData = allProperties.map((item, index) => ({
        "#": index + 1,
        "Property Name": item.propertyName,
        "Flat Name": item.flatName,
        "Flat Number": item.flatNo,
        "Property Location": item.propertyLocation,
        "Property Address": item.propertyAddress,
        "Property Type": item.propertyType,
        "User Name": item.userName,
        "Email": item.emailAddress,
        "Contact Number": item.contactNo,
        "Short Term Monthly Rent": item.monthlyRentST,
        "Short Term Monthly Deposit": item.monthlyRentSTDeposit,
        "Long Term Monthly Rent": item.monthlyRentLT,
        "Long Term Monthly Deposit": item.monthlyRentLTDeposit,
        "Property Status": item.propertyStatus,
        "Flat Status": item.flatStatus,
        "Rent Status": item.rentStatus,
        Status: item.rentStatus,
        "Move Out Date": formatDate(item.moveOutDate),

      }));

      const worksheet = XLSX.utils.json_to_sheet(excelData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Flat Reports");

      XLSX.writeFile(workbook, "Flat_Reports.xlsx");
    } catch (error) {
      handleErrors(error);
    }
  };

  const loadLocationOptions = (inputValue, callback) => {
    setTimeout(() => {
      const filteredOptions = searchLocations
        .filter((loc) =>
          loc.locationName.toLowerCase().includes(inputValue.toLowerCase())
        )
        .map((loc) => ({
          label: loc.locationName,
          value: loc.locationName,
        }));
      callback(filteredOptions);
    }, 1000);
  };

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setLocVal(selectedOption?.value || "");
  };

  const fetchCitiesData = async () => {
    try {
      const locationsData = await fetchLocations();
      setSearchLocation(locationsData.result);
    } catch (error) {
      handleErrors(error);
    }
  };
  useEffect(() => {
    fetchCitiesData();
  }, []);

  useEffect(() => {
    const getProperties = async () => {
        try {
            const result = await fetchSWKProperty();
            setPropertieslist(result);
        } catch (error) {
            console.error('Error fetching properties:', error);
        }
    };
    getProperties();
}, []);

  const loadPropertyOptions = (inputValue, callback) => {
    const filteredProperties = propertieslist.filter((property) =>
        property.propertyName.toLowerCase().includes(inputValue.toLowerCase())
    );

    setTimeout(() => {
        callback(
            filteredProperties.map((property) => ({
                label: property.propertyName,
                value: property.propertyGuid,
            }))
        );
    }, 1000);
};


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleEntriesPerPageChange = (e) => {
    setEntriesPerPage(parseInt(e.target.value, 10));
    setCurrentPage(1);
  };

  // const handleSearchClick = () => {
  //   setSearchedTerm(selectedProperty);
  // };

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);

  const totalPages = Math.ceil(totalCount / entriesPerPage);


  const handleCopy = (flatDetails) => {
    navigator.clipboard.writeText(flatDetails);
    toast.success("Flat Details Copied");
  };

  const generateWhatsAppMessage = (property) => {
    return `
  *Property Name*: ${property.propertyName}
  *Property Type*: ${property.propertyType}
  *Price*:
      Short Stay - ₹${property.monthlyRentST} Rent | ₹${property.monthlyRentSTDeposit} Deposit
      Long Stay - ₹${property.monthlyRentLT} Rent | ₹${property.monthlyRentLTDeposit} Deposit
  *Flat Name*: ${property.flatName} (${property.flatNo})
  *Address*: ${property.propertyAddress}
  *Google Map*: http://maps.google.com/?q=${property.propertyLat},${property.propertyLong}`;
  };

  const handleWhatsAppShare = (property) => {
    const message = generateWhatsAppMessage(property);
    const encodedMessage = encodeURIComponent(message);
    window.open(`https://wa.me/?text=${encodedMessage}`, "_blank");
  };
  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };
  return (
    <>
      <style>
        {`
                   .table>:not(caption)>*>* {
                      padding: .75rem 0.5rem !important;
                    }
                    .ri-pencil-fill:before {
                      display:none;
                    }
                    .ri-delete-bin-6-line:before {
                      display:none;
                    }
                    table td:nth-child(14){ display:none;} 
                `}
      </style>
      {pageAccessDetails.viewAccess ? (
        <div className="row">
          <div className="col-xxl-12">
            <div className="card mt-xxl-n5">
              <div className="card-header responsive-filter-type justify-content-between">
                <h5 className="mb-sm-2 mt-sm-2">Flat Reports</h5>
                {pageAccessDetails.downloadAccess ? (
                  <button
                    className="btn btn-download"
                    onClick={handleDownloadReport}
                    disabled={downloadBtnDisable}
                  >
                    <img src={allImages.Excel} alt="Flat Report Export to excel" width="24px" />  <span>{downloadBtnDisable ? 'Exporting...' : 'Export To Excel' }</span>
                  </button>
                ) : ''}
              </div>
              <div className="card-body manage-amenity-master-card-body">
                <div className="responsive-filter mb-3 row gy-3">
                  <div className="search-input col-lg-3 col-md-6 col-sm-12">
                    <label htmlFor="search" className="form-label me-2">
                      Search:
                    </label>
                    <AsyncSelect
                      cacheOptions
                      loadOptions={loadPropertyOptions}
                      defaultOptions={propertieslist.map((property) => ({
                        label: property.propertyName,
                        value: property.propertyGuid,
                      }))}
                      onChange={(selectedOption) => setSelectedProperty(selectedOption?.label || '')}
                      value={selectedProperty ? { label: selectedProperty, value: propertieslist.find((property) => property.propertyName === selectedProperty)?.propertyGuid } : null}
                      isClearable
                      placeholder="Select Property Name"
                    />
                  </div>
                  <div className="date-filters  col-lg-2 col-md-6 col-sm-12">
                    <label htmlFor="fromDate" className="form-label me-2">
                      From Date:
                    </label>
                    <Flatpickr
                      id="fromDate"
                      className="form-control"
                      placeholder="Select From Date"
                      value={fromDate}
                      onChange={([date]) => setFromDate(date)}
                      options={{
                        dateFormat: "Y-m-d",
                        monthSelectorType: "static",
                      }}
                    />
                  </div>

                  <div className="date-filters col-lg-2 col-md-6 col-sm-12">
                    <label htmlFor="toDate" className="form-label me-2">
                      To Date:
                    </label>
                    <Flatpickr
                      id="toDate"
                      className="form-control"
                      placeholder="Select To Date"
                      value={toDate}
                      onChange={([date]) => setToDate(date)}
                      options={{
                        dateFormat: "Y-m-d",
                        monthSelectorType: "static",
                      }}
                    />
                  </div>
                  <div className=" col-md-6 col-lg-2 col-sm-12">
                    <label htmlFor="search" className="form-label me-2">
                      Flat Status:
                    </label>
                    <select
                      className="form-select"
                      value={flatStatus}
                      onChange={(e) => setflatStatus(e.target.value)}
                    >
                      <option value="">Select Status</option>
                      <option value="Vacant">Vacant</option>
                      <option value="Occupied">Occupied</option>
                    </select>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <label htmlFor="location" className="form-label me-2">
                      Location:
                    </label>
                    <AsyncSelect
                      cacheOptions
                      loadOptions={loadLocationOptions}
                      defaultOptions={searchLocations.map((loc) => ({
                        label: loc.locationName,
                        value: loc.locationName,
                      }))}
                      value={selectedOption}
                      onChange={handleChange}
                      placeholder="Select Location"
                      isClearable
                    />

                  </div>
                  <div className="col-lg-2 col-md-6 col-sm-12">
                    <label htmlFor="entriesPerPage" className="form-label me-2">
                      Show entries:
                    </label>
                    <select
                      className="form-select"
                      id="entriesPerPage"
                      value={entriesPerPage}
                      onChange={handleEntriesPerPageChange}
                    >
                      <option value="30">30</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                    </select>


                  </div>
                  <div className=" pt-2 col-lg-8 col-md-6 col-sm-12">
                    
                  </div>
                  <div className="col-lg-2 col-md-6 col-sm-12 pt-4 pb-0">
                    <p>
                      <b>Total Number of Flats: {totalCount}</b>{" "}
                    </p>
                  </div>
                </div>

                {loading ? (
                  <Loading />
                ) : (
                  <div className="table-responsive">
                    <table className="table table-nowrap align-middle table-bordered">
                      <TableHeader
                        columns={[
                          "#",
                          "Property Name",
                          "Flat Number",
                          "Status",
                          "Publish Flat",
                          "Period",
                          "User Details",
                          "Owner Rent",
                          "Current Rent",
                          "Security Deposit",
                          "Utility Amount",
                          "Onboarding Amount",
                          "Maintainence Amount",
                        ]}
                      />
                      <tbody className="manage-amenity-master-table-values p-3">
                        {properties.length > 0 ? (
                          properties.map((item, index) => (
                            <TablesRow
                              key={item.id}
                              rowData={{
                                id:
                                  (currentPage - 1) * entriesPerPage + index + 1,
                                propertyname: (
                                  <>
                                    <div>
                                      <Link
                                        to={`/property/detail/${item.propertyGuid}`}
                                        style={{ textDecoration: "none" }}
                                      >
                                        {item.propertyName} ({item.propertyType})
                                      </Link>
                                    </div>
                                    <div>
                                      <i
                                        className="ri-map-pin-2-fill"
                                        style={{ fontSize: "15px" }}
                                      ></i>
                                      {item.propertyLocation}
                                    </div>
                                  </>
                                ),
                                flatdetails: (
                                  <>
                                    <div className="d-flex">
                                      {item.flatNo} - ({item.furnished})
                                      <span
                                        style={{
                                          marginLeft: "5px",
                                          cursor: "pointer",
                                          color: '#180f5a'
                                        }}
                                        onClick={() =>
                                          handleCopy(
                                            `Property Name: ${item.propertyName} (${item.propertyType}),
                                          Flat Name: ${item.flatName} Copied (${item.flatNo})
                                          Price: Short Stay ₹.${item.monthlyRentST} Rent | ₹${item.monthlyRentSTDeposit} Deposit
                                          Long Stay - ₹${item.monthlyRentLT} Rent | ₹${item.monthlyRentLTDeposit} Deposit
                                          Address: ${item.propertyAddress}
                                          Google Map: http://maps.google.com/?q=${item.propertyLat},${item.propertyLong}`
                                          )
                                        }
                                      >
                                        <i
                                          className="ri-file-copy-fill"
                                          style={{
                                            fontSize: "18px",
                                            cursor: "pointer",
                                          }}
                                        ></i>
                                      </span>
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          color: 'green'
                                        }}
                                        onClick={() => handleWhatsAppShare(item)}
                                      >
                                        <i
                                          className="ri-whatsapp-fill"
                                          style={{
                                            fontSize: "18px",
                                            cursor: "pointer",
                                          }}
                                        ></i>
                                      </span>
                                    </div>
                                  </>
                                ),
                                status: (
                                  <span
                                    className={`badge rounded-pill ${item.rentStatus === "Vacant"
                                      ? "badge-soft-danger"
                                      : "badge-soft-success"
                                      }`}
                                  >
                                    {item.rentStatus}
                                  </span>
                                ),
                                publish: (
                                  <ToggleFLatPublish
                                    flatId={item.id}
                                    initialStatus={item.flatStatus}
                                  />
                                ),
                                vacantdate: `${formatDate(item.moveInDate)} - ${formatDate(item.moveOutDate)}`,
                                userdetails: (
                                  <>
                                    {item.userName ? (
                                      <>
                                        <div>
                                          <Link
                                            to={`/orders/detail/${item.orderGuid}`}
                                            style={{ textDecoration: "none" }}
                                          >
                                            {item.userName}
                                          </Link>
                                        </div>
                                        <div>
                                          <i
                                            className="ri-mail-fill"
                                            style={{ fontSize: "12px" }}
                                          ></i>{" "}
                                          <span style={{ fontWeight: 600 }}>
                                            {item.emailAddress}
                                          </span>
                                        </div>
                                        <div>
                                          <i
                                            className="ri-phone-fill"
                                            style={{ fontSize: "12px" }}
                                          ></i>{" "}
                                          <span style={{ fontWeight: 600 }}>
                                            {item.contactNo}
                                          </span>
                                        </div>
                                      </>
                                    ) : (
                                      "-"
                                    )}
                                  </>
                                ),
                                ownerrent: item.ownerRent || '-',
                                currentrent: item.currentRent || '-',
                                securityamount:item.securityDeposit || '-',
                                utilityamount:item.utility || '-',
                                onboarding:item.onBoarding || '-',
                                maintainrent:item.maintenanceAmount || '-',
                              }}
                              columns={[
                                "id",
                                "propertyname",
                                "flatdetails",
                                "status",
                                "publish",
                                "vacantdate",
                                "userdetails",
                                "ownerrent",
                                "currentrent",
                                "securityamount",
                                "utilityamount",
                                "onboarding",
                                "maintainrent"
                              ]}
                              hideIcons={false}
                              showIcons={false}
                              pageLevelAccessData={pageAccessDetails}
                            />
                          ))
                        ) : (
                          <TableDataStatusError colspan="10" />
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                  totalEntries={totalCount}
                  entriesPerPage={entriesPerPage}
                />
              </div>
            </div>
          </div>
        </div>
      ) : ''}
    </>
  );
};
