import React, { useState, useCallback, useRef, useEffect } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { Loading } from '../../Common/OtherElements/Loading';

const containerStyle = {
    width: '100%',
    height: '400px',
};

const center = {
    lat: 12.9716,
    lng: 77.5946,
};

export const MapSection = ({ onAddressSelect, onLatChange, onLngChange, onMapUrlChange, initialAddress, initialLat, initiallong }) => {
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyDJyD3-p6mNlyCMxQrJVA2Cu8CgL800A9s",
        libraries: ['places']
    });

    const [map, setMap] = useState(null);
    const [location, setLocation] = useState({
        lat: initialLat || center.lat,
        lng: initiallong || center.lng
    });
    const [locationDetails, setLocationDetails] = useState({
        lat: initialLat || center.lat,
        lng: initiallong || center.lng,
        address: initialAddress || 'Bangalore, India',
    });
    const searchInputRef = useRef(null);

    useEffect(() => {
        if (isLoaded && searchInputRef.current) {
            const autocomplete = new window.google.maps.places.Autocomplete(searchInputRef.current);
            autocomplete.setFields(['geometry', 'formatted_address']);
            autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();
                if (place.geometry) {
                    const newLocation = {
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng(),
                    };
                    setLocation(newLocation);
                    const address = place.formatted_address || 'Address not found';
                    setLocationDetails({
                        lat: newLocation.lat,
                        lng: newLocation.lng,
                        address: address,
                    });
                    if (map) {
                        map.panTo(newLocation);
                    }
                }
            });
        }
    }, [isLoaded, map]);

    const onLoad = useCallback((map) => {
        const bounds = new window.google.maps.LatLngBounds(location);
        map.fitBounds(bounds);
        setMap(map);
    }, [location]);

    const onUnmount = useCallback(() => {
        setMap(null);
    }, []);

    const handleSaveAddress = () => {
        const mapUrl = `https://www.google.com/maps?q=${locationDetails.lat},${locationDetails.lng}&z=15&output=embed`;
        onAddressSelect(locationDetails.address);
        onLatChange(locationDetails.lat);
        onLngChange(locationDetails.lng);
        onMapUrlChange(mapUrl);
    };

    const mapUrl = `https://www.google.com/maps?q=${locationDetails.lat},${locationDetails.lng}&z=15&output=embed`;

    if (loadError) {
        return <div>Error loading maps</div>;
    }

    return isLoaded ? (
        <>
            <div style={{ marginBottom: '10px' }}>
                <input
                    type="text"
                    ref={searchInputRef}
                    placeholder="Search for a place"
                    style={{ width: '300px', padding: '8px', borderRadius: '4px' }}
                />
            </div>

            <GoogleMap
                mapContainerStyle={containerStyle}
                center={location}
                zoom={12}
                onLoad={onLoad}
                onUnmount={onUnmount}
            >
                <Marker position={location} />
            </GoogleMap>

            <div style={{ marginTop: '20px' }}>
                <p className="mb-2 fw-semibold" style={{ color: '#150b6d' }}>Selected Location</p>
                <h5 className="fs-14 mb-1">
                    <span id="invoice-no" style={{ fontWeight: 600, color: '#000' }}>
                        Address : {locationDetails.address}
                    </span>
                </h5>
                <h5 className="fs-14 mb-1">
                    <span id="invoice-no" style={{ fontWeight: 600, color: '#000' }}>
                       Latitude :  {locationDetails.lat}
                    </span>
                </h5>
                <h5 className="fs-14 mb-1">
                    <span id="invoice-no" style={{ fontWeight: 600, color: '#000' }}>
                       Longitude : {locationDetails.lng}
                    </span>
                </h5>
                <h5 className="fs-14 mb-1">
                    <span id="invoice-no" style={{ fontWeight: 600, color: '#000' }}>
                       Map URL : <a href={mapUrl} target="_blank" rel="noopener noreferrer">{mapUrl}</a>
                    </span>
                </h5>
                
                <button className="btn btn-secondary" onClick={handleSaveAddress} style={{ marginTop: '10px' }}>Save Address</button>
            </div>
        </>
    ) : (
        <Loading/>
    );
};
