import axiosInstance from '../Interceptors/axiosInstance.jsx';
import Cookies from 'js-cookie';

const headers = {
    'accept': '*/*',
    'Authorization': `Bearer ${Cookies.get('accessToken')}`,
    'Content-Type': 'application/json',
};

export const AddFlatGallery = async (formData) => {
        const response = await axiosInstance.post('properties/flat/gallery', formData, {  headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${Cookies.get('accessToken')}`,
              }, });
        return response.data;
};


export const fetchFlatGallery = async (flatguid) => {
        const response = await axiosInstance.get(`properties/flat/gallery/all/${flatguid}`, { headers });
        return response.data.result;
};

export const deleteFlatGalleryItem = async (galleryId) => {
        await axiosInstance.delete(`properties/flat/gallery/${galleryId}`, { headers });
};

export const fetchGalleryById = async (galleryId) => {
        const response = await axiosInstance.get(`properties/flat/gallery/${galleryId}`, { headers });
        return response.data.result;
};

export const updateGallery = async (payload) => {
        const response = await axiosInstance.put('properties/flat/gallery', payload, {  headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${Cookies.get('accessToken')}`,
              }, });
        return response.data;
};