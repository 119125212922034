import React, { useEffect, useState } from 'react';
import TableHeader from '../../../Common/TableComponent/TableHeader';
import TablesRow from '../../../Common/TableComponent/TablesRow';
import { fetchFlatGallery, deleteFlatGalleryItem } from '../../../../services/FlatGalleryService';
import allImages from '../../../../assets/images-import';
import Swal from 'sweetalert2';
import { confirmDelete } from '../../../Common/OtherElements/confirmDeleteClone';
import { Loading } from '../../../Common/OtherElements/Loading';
import { TableDataStatusError } from '../../../Common/OtherElements/TableDataStatusError';
import { handleErrors } from '../../../../utils/errorHandler';
import { usePageLevelAccess } from '../../../../hooks/usePageLevelAccess';
import { useNavigate } from 'react-router-dom';

export const ManageFlatGallery = ({ flatId, onEdit }) => {
    const [currentData, setCurrentData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageAccessDetails, setPageAccessDetails] = useState([]);
    const PageLevelAccessurl = '/property/detail/:id';
    const navigate = useNavigate();
    const { pageAccessData } = usePageLevelAccess(PageLevelAccessurl);

    useEffect(() => {
        if (pageAccessData) {
            if (!pageAccessData.viewAccess) {
                navigate('/400-error-page');
            } else {
                setPageAccessDetails(pageAccessData);
            }

        } else {
            console.log('No page access details found');
        }
    },[pageAccessData, navigate])

    useEffect(() => {
        const getFlatsGallery = async () => {
            try {
                const data = await fetchFlatGallery(flatId);
                setCurrentData(data);
            } catch (err) {
                handleErrors(err);
            } finally {
                setLoading(false);
            }
        };

        getFlatsGallery();
    }, [flatId]);

    const handleDelete = async (galleryId) => {
        const confirmed = await confirmDelete('Gallery item');
        if (confirmed) {
            try {
                await deleteFlatGalleryItem(galleryId);
                setCurrentData(prev => prev.filter(item => item.id !== galleryId));
                Swal.fire('Deleted!', 'The gallery item has been deleted successfully.', 'success');
            } catch (error) {
                handleErrors(error);
            }
        }
    };
    return (
        <>
            {pageAccessDetails.viewAccess ? (
                <div className="card-body manage-page-group-card-body table-responsive">
                    {loading ? (
                        <Loading />
                    ) : (

                        <table className="table align-middle table-bordered flat-detail-table">
                            <TableHeader columns={['File Type', 'File Link', 'File Alt', 'File Name', 'Added On', 'Action']} />
                            <tbody>
                                {currentData.length === 0 ? (
                                    <TableDataStatusError colspan="6" />
                                ) : (
                                    currentData.map((item) => (
                                        <TablesRow
                                            key={item.id}
                                            rowData={{
                                                filetype: item.fileType,
                                                filelink: item.fileType === 'Image' ? (
                                                    <img src={item.fileLink} alt={item.fileAlt} width="50px" height="50px" />
                                                ) : (
                                                    <a href={item.fileLink} target="_blank" rel="noopener noreferrer">
                                                        <img src={allImages.playButton} alt='play button' width="40px" height="40px" />
                                                    </a>
                                                ),
                                                filealt: item.fileAlt,
                                                filename: item.fileNm,
                                                addedon: new Date(item.addedOn).toLocaleDateString(),
                                            }}
                                            columns={['filetype', 'filelink', 'filealt', 'filename', 'addedon']}
                                            hideIcons={false}
                                            onEdit={() => onEdit(item)}
                                            onDelete={() => handleDelete(item.id)}
                                            pageLevelAccessData={pageAccessDetails}
                                        />
                                    ))
                                )}
                            </tbody>
                        </table>
                    )}

                </div>
            ) : ''}
        </>
    );
};
