import React, { useEffect, useState } from 'react';
import TableHeader from '../../Common/TableComponent/TableHeader';
import TablesRow from '../../Common/TableComponent/TablesRow';
import { useNavigate } from 'react-router-dom';
import { fetchFlats, deleteFlat, cloneFlat } from '../../../services/FlatMasterService';
import GalleryModal from './GalleryModal';
import ToggleSwitch from '../Flats/ToggleSwitch';
import ToggleSwitchFeatured from '../Flats/ToggleSwitchFeatured';
import Swal from 'sweetalert2';
import { confirmDelete, confirmClone } from '../../Common/OtherElements/confirmDeleteClone';
import { Loading } from '../../Common/OtherElements/Loading';
import { TableDataStatusError } from '../../Common/OtherElements/TableDataStatusError';
import { handleErrors } from '../../../utils/errorHandler';

export const ManageFlatsDetails = ({ propertyDetails, pageLevelAccessData }) => {
    const [currentData, setCurrentData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [selectedFlatId, setSelectedFlatId] = useState(null);
    const [selectedFlatName, setSelectedFlatName] = useState(null);
    const [bhkTypes, setBhkTypes] = useState([]);
    const [selectedBhkType, setSelectedBhkType] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    const navigate = useNavigate();
    useEffect(() => {
        const getFlats = async () => {
            try {
                const data = await fetchFlats(propertyDetails.propertyGuid);
                setCurrentData(data);

                if (data && Array.isArray(data)) {
                    const uniqueBhkTypes = Array.from(new Set(data.map(flat => flat.bhkType))).map(bhk => ({ value: bhk, label: bhk }));
                    setBhkTypes(uniqueBhkTypes);
                } else {
                    console.warn('Data is not in the expected format:', data);
                }
            } catch (err) {
                handleErrors(err);
            } finally {
                setLoading(false);
            }
        };

        if (propertyDetails?.propertyGuid) {
            getFlats();
        }
    }, [propertyDetails?.propertyGuid]);

    const handleDelete = async (flatid) => {
        const confirmed = await confirmDelete('Flat');
        if (confirmed) {
            try {
                await deleteFlat(flatid);
                setCurrentData(prev => prev.filter(item => item.id !== flatid));
                Swal.fire('Deleted!', 'The flat has been deleted successfully.', 'success');
            } catch (error) {
                handleErrors(error);
            }
        }
    };

    const handleClone = async (flatGuid) => {
        const confirmed = await confirmClone('Flat');
        if (confirmed) {
            try {
                await cloneFlat(flatGuid);
                const data = await fetchFlats(propertyDetails.propertyGuid);
                setCurrentData(data);
                Swal.fire('Cloned!', 'The flat has been cloned successfully.', 'success');
            } catch (error) {
                handleErrors(error);
            }
        }
    };

    const handleStatusChange = (id, newStatus) => {
        setCurrentData((prevProperties) =>
            prevProperties.map((property) =>
                property.id === id ? { ...property, status: newStatus } : property
            )
        );
    };

    const handleShowModal = (flatId, flatName) => {
        setSelectedFlatId(flatId);
        setSelectedFlatName(flatName);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleBhkTypeChange = (event) => {
        setSelectedBhkType(event.target.value);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredData = currentData.filter(item => {
        const matchesBhkType = selectedBhkType ? item.bhkType === selectedBhkType : true;
        const matchesSearchTerm = item.flatName.toLowerCase().includes(searchTerm.toLowerCase());
        return matchesBhkType && matchesSearchTerm;
    });

    return (
        <>
            <style>
                {`
                   .table>:not(caption)>*>* {
                      padding: .75rem 0.5rem !important;
                    }
                `}
            </style>
            <div className="manage-flats-details">
                <h5 className="mb-sm-2 mt-sm-2 p-3">View Flats</h5>
                <div className="card-body manage-amenity-master-card-body">
                    <div className="pagination-details-responsive  justify-content-between mb-3">
                        <div className="d-flex flex-grow-1 me-3">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                style={{ maxWidth: '300px' }}
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                            <select
                                className="form-select ms-3"
                                aria-label="BHK Type Filtering"
                                style={{ maxWidth: '200px' }}
                                value={selectedBhkType}
                                onChange={handleBhkTypeChange}
                            >
                                <option value="">BHK Type</option>
                                {bhkTypes.map((bhk) => (
                                    <option key={bhk.value} value={bhk.value}>
                                        {bhk.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="flat-details-filter">
                            <button
                                type="button"
                                className="btn btn-secondary waves-effect waves-light"
                                onClick={() => navigate(`/property/detail/add-flat/${propertyDetails.propertyGuid}`)}
                            >
                                + Add Flats
                            </button>

                        </div>
                    </div>
                    {loading ? (
                        <Loading />
                    ) : (
                        <table className="table align-middle table-bordered">
                            <TableHeader columns={['Flat No', 'Flat Name', 'BHK Type', 'Owner Rent', 'Status', 'Published?', 'Featured?', 'Gallery', 'Action']} />
                            <tbody>
                                {filteredData.length === 0 ? (
                                    <TableDataStatusError colspan={9} />
                                ) : (
                                    filteredData.map((item) => (
                                        <TablesRow
                                            key={item.id}
                                            rowData={{
                                                flatno: item.flatNo,
                                                flatDetails: (
                                                    <>

                                                        <div>{item.flatName} <span style={{ fontWeight: 500 }}>({item.furnished})</span></div>
                                                        <div style={{ fontWeight: 400 }}><i className="ri-user-fill" style={{ fontSize: '15px' }}></i>Managed By: {item.propertyManagedBy}</div>

                                                    </>
                                                ),
                                                bhkType: item.bhkType,
                                                ownerrent: `Rs. ${item.ownerRent}`,
                                                status: (
                                                    <span style={{ fontSize: '12px' }} className={`badge badge-soft-${item.status === 'Active' ? 'success' : 'danger'} badge-border`}>
                                                        {item.status === 'Active' ? 'Active' : 'Draft'}
                                                    </span>
                                                ),
                                                published: (
                                                    <ToggleSwitch
                                                        flatId={item.id}
                                                        initialStatus={item.status}
                                                        onStatusChange={(newStatus) => handleStatusChange(item.id, newStatus)}
                                                    />
                                                ),
                                                featured: (
                                                    <ToggleSwitchFeatured
                                                        flatId={item.id}
                                                        initialStatus={item.featured}
                                                    />
                                                ),
                                                gallery: (
                                                    <>
                                                        {pageLevelAccessData.addAccess ? (
                                                            <button
                                                                type="button"
                                                                className="badge text-bg-danger no-border"
                                                                onClick={() => handleShowModal(item.flatGuid, item.flatName)}
                                                            >
                                                                +Add({item.galleryCount})
                                                            </button>
                                                        ) : (
                                                            <span style={{color:'#dc3545'}}>
                                                            Forbidden
                                                        </span>
                                                        )}
                                                    </>
                                                ),
                                            }}
                                            columns={['flatno', 'flatDetails', 'bhkType', 'ownerrent', 'status', 'published', 'featured', 'gallery']}
                                            hideIcons={false}
                                            showIcons={true}
                                            onEdit={() => {
                                                navigate(`/property/detail/update-flat/${item.id}`);
                                                window.scrollTo({
                                                    top: 0,
                                                    behavior: 'smooth'
                                                });
                                            }}
                                            onDelete={() => handleDelete(item.id)}
                                            onClone={() => handleClone(item.flatGuid)}
                                            pageLevelAccessData={pageLevelAccessData}
                                        />

                                    ))
                                )}
                            </tbody>
                        </table>
                    )}
                </div>

                <GalleryModal show={showModal} handleClose={handleCloseModal} flatId={selectedFlatId} flatName={selectedFlatName} />
            </div>
        </>
    );
};
