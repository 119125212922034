import React, { useEffect, useState } from 'react';
import TableHeader from '../../../Common/TableComponent/TableHeader';
import TablesRow from '../../../Common/TableComponent/TablesRow';
import { useNavigate } from 'react-router-dom';
import { getPropertyOwnersProperties } from '../../../../services/propertyOwnerService';
import { Loading } from '../../../Common/OtherElements/Loading';
import { TableDataStatusError } from '../../../Common/OtherElements/TableDataStatusError';
import { handleErrors } from '../../../../utils/errorHandler';

export const ManageOwnerProperty = ({ ownerguid }) => {
    const [currentData, setCurrentData] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {
        const getFlats = async () => {
            try {
                const data = await getPropertyOwnersProperties(ownerguid);
                setCurrentData(data.data.result);

               
            } catch (err) {
                handleErrors(err);
            } finally {
                setLoading(false);
            }
        };

        if (ownerguid) {
            getFlats();
        }
    }, [ownerguid]);

    const totalOwnerRent = currentData.reduce((total, item) => total + (parseFloat(item.ownerRent) || 0), 0).toFixed(2);

    return (
        <>
             <style>
                {`
                   .table>:not(caption)>*>* {
                      padding: .75rem 0.5rem !important;
                    }
                    .ri-pencil-fill:before {
                      display:none;
                    }
                    .ri-delete-bin-6-line:before {
                      display:none;
                    }
                    table td:nth-child(10){ display:none;} 
                `}
            </style>
            <div className="manage-flats-details">
            <h5 className="mt-sm-2 ps-3">Total Owner Rent : {totalOwnerRent}</h5>
                <div className="card-body manage-amenity-master-card-body">

                    {loading ? (
                        <Loading />
                    ) : (
                        <table className="table align-middle table-bordered">
                            <TableHeader columns={['Property', 'Flat Size', 'BHK Type', 'Managed By', 'Preferred tenants', 'Furnished', 'Owner Rent', 'Long Term Rent','Short Term Rent']} />
                            <tbody>
                                {currentData.length === 0 ? (
                                    <TableDataStatusError colspan={9} />
                                ) : (
                                    currentData.map((item) => (
                                        <TablesRow
                                            key={item.id}
                                            rowData={{
                                                property: (
                                                    <>
                                                        <span>{item.propertyName}</span><br />
                                                        <span>{item.flatNo ? (`Flat Number:${item.flatNo}`) : ''}</span>
                                                    </>
                                                ),
                                                flatsize: item.flatSize || '-',
                                                bhkType: item.bhkType || '-',
                                                propertyManagedBy: item.propertyManagedBy || '-',
                                                preferredTenants: item.preferredTenants || '-',
                                                furnished: item.furnished || '-',
                                                ownerRent: item.ownerRent || '-',
                                                monthlyRentLT: item.monthlyRentLT || '-',
                                                monthlyRentST:item.monthlyRentST || '-',
                                            }}
                                            columns={['property', 'flatsize', 'bhkType', 'propertyManagedBy', 'preferredTenants', 'furnished', 'ownerRent', 'monthlyRentLT','monthlyRentST']}
                                            hideIcons={false}
                                            showIcons={true}
                                            onEdit={() => {
                                                navigate(`/property/detail/update-flat/${item.id}`);
                                                window.scrollTo({
                                                    top: 0,
                                                    behavior: 'smooth'
                                                });
                                            }}
                                            pageLevelAccessData=''
                                        />

                                    ))
                                )}
                            </tbody>
                        </table>
                    )}
                </div>


            </div>
        </>
    );
};
