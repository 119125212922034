import React, { useState, useEffect } from 'react';
import 'react-phone-number-input/style.css';
import "flatpickr/dist/flatpickr.min.css";
import Flatpickr from "react-flatpickr";
import { useParams } from 'react-router-dom';
import { validateAssetForm } from './../../utils/validation';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { fetchProduct } from '../../services/ProductService';
import { fetchVendor } from '../../services/VendorService';
import { addAssets, fetchAssetById, updateAsset } from '../../services/AssetsService';
import { Link } from 'react-router-dom';
import { handleErrors } from '../../utils/errorHandler';
import AsyncSelect from 'react-select/async';
import { usePageLevelAccess } from '../../hooks/usePageLevelAccess';
import { useNavigate } from 'react-router-dom';

export const AddAssetManagement = () => {
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const { assetid } = useParams();
    const [values, setValues] = useState({
        productName: '', brandName: '', remark: '', warrantyInMonths: '', vendor: '', skuCode: '',purchaseInvoiceNo:'', serielNo: '', purchasedOn: '', expiryDate: '', amount: '', typeOfAsset: 'Tagged', quantity: 1,
    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [productName, setProductName] = useState([]);
    const [vendor, setVendor] = useState([]);
    const [PageLevelAccessurl, setPageLevelAccessurl] =useState();

    useEffect(() => {
        if(assetid){
            setPageLevelAccessurl('/assets/update/:assetid');
        } else{
            setPageLevelAccessurl('assets/add');
        }
    },[assetid])
    const navigate = useNavigate();
    const { pageAccessData } = usePageLevelAccess(PageLevelAccessurl);

    useEffect(() => {
      if (pageAccessData) {
        if(assetid){
            if (!pageAccessData.editAccess) {
                navigate('/404-error-page');
            } else {
                return;
            }
        } else{
            if (!pageAccessData.addAccess) {
                navigate('/404-error-page');
            } else {
                return;
            }
        }
      } else {
          console.log('No page access details found');
      }
  })

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const productData = await fetchProduct();
                setProductName(productData.result);
            } catch (error) {
                handleErrors(error);
            }
        };

        fetchProducts();
    }, []);

    useEffect(() => {
        const fetchVendors = async () => {
            try {
                const VendorData = await fetchVendor();
                setVendor(VendorData);
            } catch (error) {
                handleErrors(error);
            }
        };

        fetchVendors();
    }, []);

    useEffect(() => {
        if (assetid) {
            const fetchData = async () => {
                try {
                    const apidata = await fetchAssetById(assetid);
                    const data = apidata;
                    setValues({
                        productName: data.productName || '',
                        brandName: data.brandName || '',
                        remark: data.remark || '',
                        warrantyInMonths: data.warrantyInMonths || '',
                        vendor: data.vendor || '',
                        skuCode: data.skuCode || '',
                        purchaseInvoiceNo: data.purchaseInvoiceNo || '',
                        serielNo: data.serielNo || '',
                        purchasedOn: data.purchasedOn || '',
                        expiryDate: data.expiryDate || '',
                        amount: data.amount || '',
                        typeOfAsset: data.typeOfAsset || '',
                        quantity: data.quantity || '',
                    });
                } catch (error) {
                    console.error("Error fetching asset data:", error);
                }
            };
            fetchData();
        } else {
            setValues({
                productName: '',
                brandName: '',
                remark: '',
                warrantyInMonths: '',
                vendor: '',
                skuCode: '',
                purchaseInvoiceNo: '',
                serielNo: '',
                purchasedOn: '',
                expiryDate: '',
                amount: '',
                typeOfAsset: 'Tagged',
                quantity: '1',
            });
        }
    }, [assetid]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'typeOfAsset') {
            if (value === 'Tagged') {
                setValues({
                    ...values,
                    [name]: value,
                    quantity: 1
                });
            } else {
                setValues({
                    ...values,
                    [name]: value,
                    quantity: values.quantity || 1
                });
            }
        } else {
            setValues({
                ...values,
                [name]: value
            });
        }
    };

    const loadProductOptions = (inputValue, callback) => {
        const filteredProducts = productName.filter(product =>
            product.productName.toLowerCase().includes(inputValue.toLowerCase())
        );

        setTimeout(() => {
            callback(filteredProducts.map(product => ({
                label: product.productName,
                value: product.productName,
            })));
        }, 1000);
    };

    const loadVendorOptions = (inputValue, callback) => {
        const filteredVendors = vendor.filter(vend =>
            vend.vendorName.toLowerCase().includes(inputValue.toLowerCase())
        );

        setTimeout(() => {
            callback(filteredVendors.map(vend => ({
                label: vend.vendorName,
                value: vend.id,
            })));
        }, 1000);
    };

    const handleSubmit = async (e, action) => {
        e.preventDefault();
        const validationErrors = validateAssetForm(values);
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            setIsSubmitting(true);
            try {
                const payload = {
                    id: assetid,
                    productName: values.productName,
                    brandName: values.brandName,
                    remark: values.remark,
                    warrantyInMonths: values.warrantyInMonths,
                    vendor: String(values.vendor),
                    skuCode: values.skuCode,
                    purchaseInvoiceNo: values.purchaseInvoiceNo,
                    serielNo: values.serielNo,
                    purchasedOn: values.purchasedOn,
                    expiryDate: values.expiryDate,
                    amount: values.amount,
                    typeOfAsset: values.typeOfAsset,
                };
                setIsButtonDisabled(true);

                if (assetid) {
                    await updateAsset(payload);
                    toast.success("Asset updated successfully!");
                } else {
                    await addAssets(values);
                    toast.success("Asset added successfully!");
                    setValues({
                        productName: '', brandName: '', remark: '', warrantyInMonths: '', vendor: '', skuCode: '',purchaseInvoiceNo:'', serielNo: '', purchasedOn: '', expiryDate: '', amount: '', typeOfAsset: 'Tagged', quantity: 1,
                    });
                }

            } catch (error) {
                toast.error("Failed to process asset details. Please try again.");
            } finally {
                setIsButtonDisabled(false);
                setIsSubmitting(false);
            }
        }
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">Asset Management</h4>
                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">
                                    <Link to="/">
                                        <i className="ri-home-2-fill"></i>
                                    </Link>
                                </li>
                                {assetid ? (
                                    <>
                                        <li className="breadcrumb-item">
                                            <Link to='/assets'>
                                                Manage Assets
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            Update Asset
                                        </li>
                                    </>
                                ) : (
                                    <>
                                        <li className="breadcrumb-item">
                                            Add Asset
                                        </li>
                                    </>
                                )}

                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-xxl-12">
                        <div className="card mt-xxl-n5">
                            <div className="card-header">
                                <h5 className="mb-sm-1 mt-sm-1">{assetid ? 'Update Asset' : 'Add Asset'}</h5>
                            </div>
                            <div className="card-body p-4">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-12 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="productName" className="form-label">Product Name <span className='required-field'>*</span></label>

                                            <AsyncSelect
                                                cacheOptions
                                                loadOptions={loadProductOptions}
                                                defaultOptions={productName.map(product => ({
                                                    label: product.productName,
                                                    value: product.productName,
                                                }))}
                                                onChange={(selectedOption) => {
                                                    setValues({
                                                        ...values,
                                                        productName: selectedOption?.value || '',
                                                    });
                                                }}
                                                value={{
                                                    label: productName.find(product => product.productName === values.productName)?.productName,
                                                    value: values.productName,
                                                }}
                                                isClearable
                                                placeholder="Select Product Name"
                                                className={errors.productName ? 'is-invalid' : ''}
                                            />
                                            {errors.productName && <p className="text-danger">{errors.productName}</p>}
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="typeOfAsset" className="form-label">Type of Asset</label>

                                            <select
                                                name="typeOfAsset"
                                                value={values.typeOfAsset}
                                                onChange={handleChange}
                                                className={`form-select ${errors.typeOfAsset ? 'is-invalid' : ''}`}
                                            >

                                                <option value="Tagged">Tagged</option>
                                                <option value="Non-Tagged">Non-Tagged</option>
                                            </select>
                                            {errors.typeOfAsset && <p className="text-danger">{errors.typeOfAsset}</p>}
                                        </div>
                                    </div>
                                    {assetid ? '' : (
                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                            <div className="mb-3">
                                                <label htmlFor="quantity" className="form-label">Quantity</label>
                                                <input
                                                    type="number"
                                                    name="quantity"
                                                    value={values.quantity}
                                                    placeholder="Enter quantity"
                                                    onChange={handleChange}
                                                    className={`form-control ${errors.quantity ? 'is-invalid' : ''}`}
                                                    disabled={values.typeOfAsset === 'Tagged'}
                                                />

                                                {errors.quantity && <p className="text-danger">{errors.quantity}</p>}
                                            </div>
                                        </div>
                                    )}

                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="brandName" className="form-label">Brand Name</label>
                                            <input
                                                type="text"
                                                name="brandName"
                                                value={values.brandName}
                                                placeholder='Enter Brand Name'
                                                onChange={handleChange}
                                                className={`form-control ${errors.brandName ? 'is-invalid' : ''}`}
                                            />
                                            {errors.brandName && <p className="text-danger">{errors.brandName}</p>}
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="remark" className="form-label">Specification<span className='required-field'>*</span></label>
                                            <input
                                                type="text"
                                                name="remark"
                                                value={values.remark}
                                                placeholder='Enter Specification'
                                                onChange={handleChange}
                                                className={`form-control ${errors.remark ? 'is-invalid' : ''}`}
                                            />
                                            {errors.remark && <p className="text-danger">{errors.remark}</p>}
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="warrantyInMonths" className="form-label">Warranty In Months <span className='required-field'>*</span></label>
                                            <input
                                                type="number"
                                                name="warrantyInMonths"
                                                value={values.warrantyInMonths}
                                                placeholder='Enter warrantyInMonths'
                                                onChange={handleChange}
                                                className={`form-control ${errors.warrantyInMonths ? 'is-invalid' : ''}`}
                                            />
                                            {errors.warrantyInMonths && <p className="text-danger">{errors.warrantyInMonths}</p>}
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="vendor" className="form-label">Vendor</label>

                                            <AsyncSelect
                                                cacheOptions
                                                loadOptions={loadVendorOptions}
                                                defaultOptions={vendor.map(vend => ({
                                                    label: vend.vendorName,
                                                    value: String(vend.id),
                                                }))}
                                                onChange={(selectedOption) => {
                                                    setValues({
                                                        ...values,
                                                        vendor: selectedOption ? String(selectedOption.value) : '',
                                                    });
                                                }}
                                                value={
                                                    values.vendor
                                                        ? {
                                                            label: vendor.find(vend => String(vend.id) === values.vendor)?.vendorName || '',
                                                            value: values.vendor,
                                                        }
                                                        : null
                                                }
                                                isClearable
                                                placeholder="Select Vendor"
                                                className={errors.vendor ? 'is-invalid' : ''}
                                            />


                                            {errors.vendor && <p className="text-danger">{errors.vendor}</p>}
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="skuCode" className="form-label">Stock Keeping Unit Code</label>
                                            <input
                                                type="text"
                                                name="skuCode"
                                                value={values.skuCode}
                                                placeholder='Enter skuCode'
                                                onChange={handleChange}
                                                className={`form-control ${errors.skuCode ? 'is-invalid' : ''}`}
                                            />
                                            {errors.skuCode && <p className="text-danger">{errors.skuCode}</p>}
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="purchaseInvoiceNo" className="form-label">Purchase Invoice Number</label>
                                            <input
                                                type="text"
                                                name="purchaseInvoiceNo"
                                                value={values.purchaseInvoiceNo}
                                                placeholder='Enter purchaseInvoiceNo'
                                                onChange={handleChange}
                                                maxLength={30}
                                                className={`form-control ${errors.purchaseInvoiceNo ? 'is-invalid' : ''}`}
                                            />
                                            {errors.purchaseInvoiceNo && <p className="text-danger">{errors.purchaseInvoiceNo}</p>}
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="serielNo" className="form-label">Serial Number</label>
                                            <input
                                                type="text"
                                                name="serielNo"
                                                value={values.serielNo}
                                                placeholder='Enter serielNo'
                                                onChange={handleChange}
                                                className={`form-control ${errors.serielNo ? 'is-invalid' : ''}`}
                                            />
                                            {errors.serielNo && <p className="text-danger">{errors.serielNo}</p>}
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="purchasedOn" className="form-label">Purchased On <span className='required-field'>*</span></label>
                                            <Flatpickr
                                                className={`form-control ${errors.purchasedOn ? 'is-invalid' : ''}`}
                                                value={values.purchasedOn ? new Date(values.purchasedOn) : null}
                                                placeholder='Enter Purchase Date'
                                                onChange={(date) => setValues({ ...values, purchasedOn: date.length ? date[0].toISOString() : '' })}
                                                options={{
                                                    dateFormat: "d-M-Y",
                                                    monthSelectorType: 'static',
                                                }}
                                            />
                                            {errors.purchasedOn && <p className="text-danger">{errors.purchasedOn}</p>}
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="expiryDate" className="form-label">Expiry Date <span className='required-field'>*</span></label>
                                            <Flatpickr
                                                className={`form-control ${errors.expiryDate ? 'is-invalid' : ''}`}
                                                value={values.expiryDate ? new Date(values.expiryDate) : null}
                                                placeholder='Enter Expiry Date'
                                                onChange={(date) => setValues({ ...values, expiryDate: date.length ? date[0].toISOString() : '' })}
                                                options={{
                                                    dateFormat: "d-M-Y",
                                                    monthSelectorType: 'static',
                                                }}
                                            />
                                            {errors.expiryDate && <p className="text-danger">{errors.expiryDate}</p>}
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label htmlFor="amount" className="form-label">Amount <span className='required-field'>*</span></label>
                                            <input
                                                type="text"
                                                name="amount"
                                                value={values.amount}
                                                placeholder='Enter amount'
                                                onChange={handleChange}
                                                className={`form-control ${errors.amount ? 'is-invalid' : ''}`}
                                            />
                                            {errors.amount && <p className="text-danger">{errors.amount}</p>}
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <button type="submit" className="btn btn-secondary" disabled={isButtonDisabled}>
                    {isSubmitting ? (assetid ? 'Updating' : 'Adding') : (assetid ? "Update" : "Add")}
                </button>

            </form>
        </>
    );
};
