import React from 'react'
import { DashboardIntro } from '../components/Dashboard/DashboardIntro'
import { DashboardBasicDetails } from '../components/Dashboard/DashboardBasicDetails'
import { DashboardRevenue } from '../components/Dashboard/DashboardRevenue'
import { DashboardUpcomingVacations } from '../components/Dashboard/DashboardUpcomingVacations'
import { DashboardAssetsOrders } from '../components/Dashboard/DashboardAssetsOrders'
import ComponentHeader from '../components/Common/OtherElements/ComponentHeader'

export const DashboardPage = () => {
    return (
        <>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <ComponentHeader title='Dashboard'/>
                        <div className="row">
                            <div className="col">
                                <div className="h-100">
                                    <DashboardIntro/>
                                    <DashboardBasicDetails/>
                                    <DashboardRevenue/>
                                    <DashboardUpcomingVacations/>
                                    <DashboardAssetsOrders/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
