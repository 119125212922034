import React from 'react';

export const OnboardCustomerDetails = ({ customerData }) => {
    const formatDate = (dateString) => {
        if (!dateString) return 'No Date Available';
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return 'Invalid Date';
        return new Intl.DateTimeFormat('en-GB', {
            day: 'numeric', month: 'short', year: 'numeric',
            hour: '2-digit', minute: '2-digit', hour12: false
        }).format(date);
    };

    const customerDetailsFields = [
        { label: 'Customer First Name', value: customerData.firstName || '-' },
        { label: 'Customer Last Name', value: customerData.lastName || '-' },
        { label: 'Date of Birth', value: customerData.dob || '-' },
        { label: 'Gender', value: customerData.gender || '-' },
        {
            label: 'Email Address',
            value: (
                <span>
                    {customerData.emailAddress}
                    {customerData.emailAddress ? (
                        <span className='verified-details'>
                            {customerData.emailStatus === 'Unverified' ? (
                                <i className="ri-close-circle-fill" style={{ color: '#dc3545' }}></i>
                            ) : (
                                <i className="ri-checkbox-circle-fill" style={{ color: '#198754' }}></i>
                            )}
                        </span>
                    ) : '-'}

                </span>
            ) || '-'
        },
        { label: 'Alternate Email', value: customerData.altEmail },
        {
            label: 'Phone Number', value: (
                <span>
                    {customerData.phoneNo}
                    {customerData.phoneNo ? (
                        <span className='verified-details'>
                            {customerData.phoneStatus === 'Unverified' ? (
                                <i className="ri-close-circle-fill" style={{ color: '#dc3545' }}></i>
                            ) : (
                                <i className="ri-checkbox-circle-fill" style={{ color: '#198754' }}></i>
                            )}
                        </span>
                    ) : '-'}

                </span>
            ) || '-'
        },
        { label: 'Alternate Phone Number', value: customerData.altPhoneNo },
        {
            label: 'WhatsUp Number', value: (
                <span>
                    {customerData.whatsApp}
                    {customerData.whatsApp ? (
                        <span className='verified-details'>
                            {customerData.whatsAppStatus === 'Unverified' ? (
                                <i className="ri-close-circle-fill" style={{ color: '#dc3545' }}></i>
                            ) : (
                                <i className="ri-checkbox-circle-fill" style={{ color: '#198754' }}></i>
                            )}
                        </span>
                    ) : '-'}

                </span>
            ) || '-'
        },
        { label: 'Nationality', value: customerData.nationality || '-' },
    ];

    const AddressFields = [
        { label: 'City', value: customerData.city || '-' },
        { label: 'State', value: customerData.state || '-' },
        { label: 'Country', value: customerData.country || '-' },
        { label: 'Pincode', value: customerData.pincode || '-' },
    ];

    return (
        <div className="row justify-content-center">
            <div className="col-xxl-12">
                <div className="card-header">
                    <h5 className="mb-sm-2 mt-sm-2">Customer Details</h5>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card-body">
                            <div className="row g-3 pb-4">
                                {customerDetailsFields.map((field, index) => (
                                    <div className="col-lg-3 col-6 mb-3" key={index}>
                                        <p className="mb-2 fw-semibold">{field.label}</p>
                                        <h5 className="fs-14 mb-0">
                                            <span id="invoice-no" className='sub-title-detail'>
                                                {field.value}
                                            </span>
                                        </h5>
                                    </div>
                                ))}
                            </div>
                            <div className='profile-info-fields'>
                                <h5 className="mb-sm-1 mt-sm-1">Customer Address</h5>
                            </div>
                            <div className="row g-3 pt-3">
                            <div className="col-lg-12 col-6 mb-3">
                                    <p className="mb-2 fw-semibold">Street Address</p>
                                    <h5 className="fs-14 mb-0">
                                        <span id="invoice-no" className='sub-title-detail'>
                                            {customerData.streetAddress || '-'}
                                        </span>
                                    </h5>
                                </div>
                                {AddressFields.map((field, index) => (
                                    <div className="col-lg-3 col-6 mb-3" key={index}>
                                        <p className="mb-2 fw-semibold">{field.label}</p>
                                        <h5 className="fs-14 mb-0">
                                            <span id="invoice-no" className='sub-title-detail'>
                                                {field.value}
                                            </span>
                                        </h5>
                                    </div>
                                ))}
                            </div>
                            <div className="row pt-4">
                                <div className="col-lg-8 col-6">
                                    <p className="mb-2 fw-semibold" style={{opacity:0.5}}>Last Logged In : {formatDate(customerData.lastLoggedIn) || '-'}  <span className="line-separator">|</span>  Last Logged In IP : {customerData.lastLoggedIp || '-'}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}