import axiosInstance from '../Interceptors/axiosInstance.jsx';
import Cookies from 'js-cookie';

const headers = {
  'accept': '*/*',
  'Authorization': `Bearer ${Cookies.get('accessToken')}`,
  'Content-Type': 'application/json',
};

export const addAssets = async (formData) => {
    const response = await axiosInstance.post('assets/add', formData, { headers });
    return response.data.result;
  };

  export const paginationAssets = async (formData) => {
    const response = await axiosInstance.post('assets/filter', formData, {headers});
    return response;
  }  

  export const deleteAsset = async (assetid) => {
    return await axiosInstance.delete(`assets/delete/${assetid}`, { headers });
  };

  export const fetchAssetById = async (id) => {
    const response = await axiosInstance.get(`assets/view/${id}`, { headers });
    return response.data.result;
  };

  export const updateAsset = async (payload) => {
    const response = await axiosInstance.put('assets/update', payload, { headers });
    return response;
  };

  //assign assets section

  export const assignAssetsDisplay = async (flatGuid) => {
    return await axiosInstance.get(`assets/assign?flatGuid=${flatGuid}`, { headers });
};

export const assignAssetsTimelineDisplay = async (assetGuid) => {
  return await axiosInstance.get(`assets/timeline?assetGuid=${assetGuid}`, { headers });
};

export const fetchAssignedAssets = async (productName) => {
  const response = await axiosInstance.post(`assets/all?productName=${productName}`, { headers });
  return response.data.result;
};

export const addAssignAssets = async (formData) => {
  const response = await axiosInstance.post('assets/assign', formData, { headers });
  return response.data.result;
};

export const changeAssignStatus = async (flatGuid, assetGuid) => {
  const response = await axiosInstance.put(`assets/un-assign?flatGuid=${flatGuid}&assetGuid=${assetGuid}`, { headers });
  return response;
};

export const viewAssignedAssetByGuid = async (assetGuid) => {
  const response = await axiosInstance.get(`assets/view-by-guid/${assetGuid}`, { headers });
  return response.data.result;
};

export const AddInventory = async (assetGuid, quantity) => {
  const response = await axiosInstance.post(`assets/add-inventory?assetGuid=${assetGuid}&quantity=${quantity}`, { headers });
  return response;
};

export const RemoveInventory = async (assetGuid, quantity) => {
  const response = await axiosInstance.delete(`assets/remove-inventory?assetGuid=${assetGuid}&quantity=${quantity}`, { headers });
  return response;
};