import axiosInstance from '../Interceptors/axiosInstance.jsx';
import Cookies from 'js-cookie';

const headers = {
  'accept': '*/*',
  'Authorization': `Bearer ${Cookies.get('accessToken')}`,
  'Content-Type': 'application/json',
};

export const createSWKProperty = async (payload) => {
  const response = await axiosInstance.post('properties/add', {
    city:payload.city,
    propertyLocation:payload.propertyLocation,
    propertyType:payload.propertyType,
    propertyName:payload.propertyName,
    noOfFlats:payload.noOfFlats,
    noOfFloors:payload.noOfFloors,
    buldingAge:payload.buldingAge,
    powerBackup:payload.powerBackup,
    waterSource:payload.waterSource,
    liftAvailble:payload.liftAvailble,
    propertyLat:payload.propertyLat,
    propertyLong:payload.propertyLong,
    propertyAddress:payload.propertyAddress,
    mapUrl:payload.mapUrl,
    handOverDate: payload.handOverDate,
    renewalDate:payload.renewalDate,
    rentStatDate: payload.rentStatDate,
    eBillGenerateDate:payload.eBillGenerateDate,
    eBillDueDate:payload.eBillDueDate,
    propertySeries:payload.propertySeries,
    propertyNote:payload.propertyNote,
  }, { headers });
  return response;
};

export const fetchSWKProperty = async () => {
  const response = await axiosInstance.get('properties/all', { headers });
  return response.data.result;
};

export const fetchSWKPropertyById = async (id) => {
  const response = await axiosInstance.get(`properties/${id}`, { headers });
  return response.data.result;
};

export const updateSWKProperty = async (payload) => {
  return await axiosInstance.put('properties/update', payload, { headers });
};

export const deleteSWKProperty = async (id) => {
  return await axiosInstance.delete(`properties/${id}`, { headers });
};

export const updateSWKPropertyStatus = async (propId, propStatus) => {
  return await axiosInstance.put(`properties/status?propId=${propId}&propStatus=${propStatus}`, { headers });
};
export const cloneSWKProperty = async (propGuid) => {
  return await axiosInstance.post(`properties/clone/${propGuid}`, { headers });
};

export const fetchSWKPropertyByGuid = async (guid) => {
  const response = await axiosInstance.get(`properties/get/${guid}`, { headers });
  return response.data.result;
};

export const paginationSWRProperty = async (formData) => {
  return await axiosInstance.post('properties/all/filter', formData, {headers});
}


