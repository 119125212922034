import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

export const SupportInfoDetails = ({ customerData }) => {
    const [showModal, setShowModal] = useState(false);

    const handleImageClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'No Date Available';
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-GB', {
            day: 'numeric', month: 'short', year: 'numeric',
            hour: '2-digit', minute: '2-digit', hour12: false
        }).format(date);
    };

    const customerDetailsFields = [
        { label: 'Support Id', value: customerData.id || '-' },
        { label: 'User Name', value: customerData.userName || '-' },
        { label: 'Property Name', value: customerData.propertyName || '-' },
        { label: 'Flat Number', value: customerData.flatNo || '-' },
        { label: 'Type of Support', value: customerData.typeOfSupport || '-' },
        { label: 'Support Status', value: customerData.supportStatus || '-' },
        { label: 'Created By', value: customerData.createdBy || '-' },
        { label: 'Updated By', value: customerData.updatedBy || '-' },
        { label: 'Created On', value: formatDate(customerData.createdOn) || '-' },
        { label: 'Updated On', value: formatDate(customerData.updatedOn) || '-' },
    ];

    const renderOrderStatusBadge = (status) => {
        let badgeClass = '';
        if (status === 'Created') {
            badgeClass = 'badge-soft-secondary'; 
        } else if (status === 'InProgress') {
            badgeClass = 'badge-soft-warning'; 
        } else if(status === 'Closed'){
            badgeClass = 'badge-soft-success'; 
        } else if(status === 'Opened'){
            badgeClass = 'badge-soft-danger';
        } else if(status === 'Resolved'){
            badgeClass = 'badge-soft-success';
        }
        return <span className={`badge rounded-pill ${badgeClass}`}>{status}</span>;
    };

    return (
        <div className="card mt-xxl-n5">
            <div className="card-header">
                <h5 className="mb-sm-1 mt-sm-1">Support Details</h5>
            </div>
            <div className="card-body p-4">
                <div className="row justify-content-center">
                    <div className="col-xxl-12">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card-body p-4">
                                    <div className="row g-3">
                                        {customerDetailsFields.map((field, index) => (
                                            <div className="col-lg-3 col-6 mb-3" key={index}>
                                                <p className="mb-2 fw-semibold">{field.label}</p>
                                                <h5 className="fs-14 mb-0">
                                                    <span id="invoice-no" className='sub-title-detail'>
                                                        {field.label === 'Support Status' ? renderOrderStatusBadge(field.value) : field.value}
                                                    </span>
                                                </h5>
                                            </div>
                                        ))}
                                        <div className="col-lg-6 col-6 mb-3">
                                            <p className="mb-2 fw-semibold">Description</p>
                                            <h5 className="fs-14 mb-0">
                                                <span id="invoice-no" className='sub-title-detail'>
                                                    {customerData.description || '-'}
                                                </span>
                                            </h5>
                                        </div>
                                        <div className="col-lg-6 col-6 mb-3">
                                            <p className="mb-2 fw-semibold">Attachment</p>
                                            {customerData.attachment ? (
                                                <img 
                                                    src={customerData.attachment} 
                                                    alt="Support management attachment" 
                                                    width='100px' 
                                                    height='100px' 
                                                    style={{ cursor: 'pointer' }} 
                                                    onClick={handleImageClick} 
                                                />
                                            ) : '-'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Attached Image</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <img src={customerData.attachment} alt="Support management attachment" className="img-fluid" />
                </Modal.Body>
            </Modal>
        </div>
    );
};
