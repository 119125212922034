import React, { useState, useEffect } from "react";
import {
  AddOwnerBankDetailsService,
  getOwnersBankDetails,
  UpdateOwnerBankDetails,
} from "../../../../services/propertyOwnerService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { handleErrors } from "../../../../utils/errorHandler";
import {Loading} from '../../../Common/OtherElements/Loading';

export const AddOwnerBankDetails = ({ ownerguid, editMode, onSuccess }) => {
  const [bankName, setBankName] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [accountName, setAccountName] = useState("");
  const [isDataPresent, setIsDataPresent] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (ownerguid) {
        try {
          const data = await getOwnersBankDetails(ownerguid);
          const newdata = data.data.result;
          if (newdata) {
            setBankName(newdata.bankName || "");
            setAccountNo(newdata.accountNo || "");
            setIfscCode(newdata.ifscCode || "");
            setAccountName(newdata.accountName || "");
            setIsDataPresent(true);
          }
        } catch (error) {
          handleErrors(error);
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };
  
    fetchData();
  }, [ownerguid]);
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      ownerGuid: ownerguid,
      bankName,
      accountNo,
      ifscCode,
      accountName,
    };

    try {
      setIsButtonDisabled(true);
      if (isDataPresent) {
        await UpdateOwnerBankDetails(formData);
        toast.success("Bank details updated successfully!");
      } else {
        await AddOwnerBankDetailsService(formData);
        toast.success("Bank details added successfully!");
      }
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      handleErrors(error);
    } finally {
      setIsButtonDisabled(false);
    }
  };

  if (isLoading) {
    return <Loading/>;
  }

  return (
    <div className="row">
      <div className="col-xxl-12">
          <div className="card-header">
            <h5 className="mb-sm-1 mt-sm-1">
              {isDataPresent ? "Update Bank Details" : "Add Bank Details"}
            </h5>
          </div>
          <div className="card-body p-4">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mb-3">
                    <label htmlFor="bankName" className="form-label">
                      Bank Name
                    </label>
                    <input
                      type="text"
                      name="bankName"
                      value={bankName}
                      placeholder="Enter Bank Name"
                      onChange={(e) => setBankName(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mb-3">
                    <label htmlFor="accountNo" className="form-label">
                      Account Number
                    </label>
                    <input
                      type="text"
                      name="accountNo"
                      value={accountNo}
                      placeholder="Enter Account Number"
                      onChange={(e) => setAccountNo(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mb-3">
                    <label htmlFor="ifscCode" className="form-label">
                      IFSC Code
                    </label>
                    <input
                      type="text"
                      name="ifscCode"
                      value={ifscCode}
                      placeholder="Enter IFSC Code"
                      onChange={(e) => setIfscCode(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="mb-3">
                    <label htmlFor="accountName" className="form-label">
                      Account Name
                    </label>
                    <input
                      type="text"
                      name="accountName"
                      value={accountName}
                      placeholder="Enter Account Name"
                      onChange={(e) => setAccountName(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="pt-4">
                    <button
                      type="submit"
                      className="btn btn-secondary pt-1 pb-1 p-3"
                      disabled={isButtonDisabled}
                    >
                      {isButtonDisabled
                        ? isDataPresent
                          ? "Updating"
                          : "Saving"
                        : isDataPresent
                        ? "Update"
                        : "Save"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
      </div>
    </div>
  );
};
