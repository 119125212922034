import React, { useEffect, useState } from 'react';
import TableHeader from '../Common/TableComponent/TableHeader';
import { orderManagementDetails } from '../../services/onboardingCustomerService';
import { SuborderManagementDetails } from '../../services/onboardingCustomerService';
import { TableDataStatusError } from '../Common/OtherElements/TableDataStatusError';
import { Link } from 'react-router-dom';
import ExtendStayModal from './ExtendStayModal';

export const OrderDetails = ({ customerGuid }) => {
    const [orderData, setOrderData] = useState([]);
    const [expandedRows, setExpandedRows] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [selectedOrderGuid, setSelectedOrderGuid] = useState(null);

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const orderApi = await orderManagementDetails(customerGuid);
                setOrderData(orderApi.data.result);
            } catch (error) {
                console.error("Error fetching customer data:", error);
            }
        };
        fetchOrders();
    }, [customerGuid]);

    const toggleSubTable = async (orderGuid) => {
        try {
            if (expandedRows[orderGuid]) {
                setExpandedRows((prev) => ({ ...prev, [orderGuid]: null }));
            } else {
                const suborderApi = await SuborderManagementDetails(orderGuid);
                setExpandedRows((prev) => ({
                    ...prev,
                    [orderGuid]: suborderApi.data.result,
                }));
            }
        } catch (error) {
            console.error("Error fetching suborder data:", error);
        }
    };

    const handleShowModal = (orderGuid) => {
        setSelectedOrderGuid(orderGuid);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedOrderGuid(null);
    };


    return (
        <>
            <div className="card-header">
                <h5 className="mb-sm-2 mt-sm-2">Order Details</h5>
            </div>
            <table className="table align-middle table-bordered table-nowrap">
                <TableHeader
                    columns={['#', 'Order Id', 'Extend Stay', 'Property', 'Stay Duration', 'No of Guests', 'Rent Amount', 'Total Amount', 'Paid Amount', 'Balance Amount', 'Status', 'Order Created On']}
                />
                <tbody className="manage-page-group-table-values">
                    {orderData.length === 0 ? (
                        <TableDataStatusError colspan="14" />
                    ) : (
                        orderData.map((order) => (
                            <React.Fragment key={order.orderGuid}>
                                <tr>
                                    <td>
                                        <span className="show-hide-sub-table"
                                            onClick={() => toggleSubTable(order.orderGuid)}
                                        >
                                            {expandedRows[order.orderGuid] ? <i className="ri-checkbox-indeterminate-line" style={{ color: '#dc3545' }}></i> : <i className="ri-add-box-line" style={{ color: '#45cb85' }}></i>}
                                        </span>
                                    </td>
                                    <td><Link to={`/orders/detail/${order.orderGuid}`} style={{ textDecoration: 'none' }}>{order.orderId}</Link></td>
                                    <td>{order.stayExtended === 'Yes' ? '-' : (
                                        <span
                                            className="badge text-bg-success"
                                            style={{ fontSize: '11px', cursor: 'pointer' }}
                                            onClick={() => handleShowModal(order.orderGuid)}
                                        >
                                            Extend Stay
                                        </span>
                                    )}

                                    </td>
                                    <td><span>{order.propertyName} ({order.stayType})</span>
                                        <br />
                                        <span>
                                            <label>Flat Number: </label>
                                            {order.flatNo}{' '}
                                            <Link to={`https://sowerent.netlify.app/property/${order.flatUrl}-${order.flatId}`} target="_blank" rel="noopener noreferrer">
                                                <i className="ri-external-link-fill"></i>
                                            </Link>
                                        </span>
                                    </td>
                                    <td>
                                        {new Date(order.moveInDate).toLocaleDateString('en-GB', {
                                            day: 'numeric',
                                            month: 'short',
                                            year: 'numeric',
                                        })}{' '}
                                        -{' '}
                                        {new Date(order.moveOutDate).toLocaleDateString('en-GB', {
                                            day: 'numeric',
                                            month: 'short',
                                            year: 'numeric',
                                        })}
                                    </td>
                                    <td>{order.noOfGuest || '-'}</td>
                                    <td>Rs. {order.rentAmount || '-'}</td>
                                    <td>Rs. {order.totalAmount || '-'}</td>
                                    <td>Rs. {order.paidAmount || '-'}</td>
                                    <td>Rs. {order.balanceAmount || '-'}</td>
                                    <td>
                                        {order.orderStatus === 'Success' && <span className="badge rounded-pill badge-soft-success">Success</span>}
                                        {order.orderStatus === 'Failed' && <span className="badge rounded-pill badge-soft-danger">Failed</span>}
                                        {order.orderStatus === 'Pending' && <span className="badge rounded-pill badge-soft-warning">Pending</span>}
                                        {order.orderStatus === 'Initiated' && <span className="badge rounded-pill badge-soft-secondary">Initiated</span>}
                                        {order.orderStatus === 'Cancelled' && <span className="badge rounded-pill badge-soft-danger">Cancelled</span>}
                                    </td>
                                    <td>
                                        {new Date(order.orderCreatedOn).toLocaleDateString('en-GB', {
                                            day: 'numeric',
                                            month: 'short',
                                            year: 'numeric',
                                        })}
                                        ,{' '}
                                        {new Date(order.orderCreatedOn).toLocaleTimeString('en-US', {
                                            hour: '2-digit',
                                            minute: '2-digit',
                                        })}
                                    </td>

                                </tr>
                                {expandedRows[order.orderGuid] && (
                                    <tr>
                                        <td colSpan="14">
                                            <table className="table table-bordered bg-soft-info" style={{ border: '#ced4da' }}>
                                                <thead class="manage-page-group-table-header bg-soft-info" style={{ border: '#ced4da' }}><tr class="form-label"><th>Order Id</th><th>Property</th><th>Stay Duration</th><th>No of Guests</th><th>Rent Amount</th><th>Total Amount</th><th>Paid Amount</th><th>Balance Amount</th><th>Status</th><th>Order Created On</th></tr></thead>
                                                <tbody>
                                                    {expandedRows[order.orderGuid].length === 0 ? (
                                                        <TableDataStatusError colspan="10" />
                                                    ) : (
                                                        expandedRows[order.orderGuid].map((order) => (
                                                            <tr key={order.suborderGuid}>
                                                                <td><Link to={`/orders/detail/${order.orderGuid}`} style={{ textDecoration: 'none' }}>{order.orderId}</Link></td>
                                                                <td><span>{order.propertyName} ({order.stayType})</span>
                                                                    <br />
                                                                    <span>
                                                                        <label>Flat Number: </label>
                                                                        {order.flatNo}{' '}
                                                                        <Link to={`https://sowerent.netlify.app/property/${order.flatUrl}-${order.flatId}`} target="_blank" rel="noopener noreferrer">
                                                                            <i className="ri-external-link-fill"></i>
                                                                        </Link>
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    {new Date(order.moveInDate).toLocaleDateString('en-GB', {
                                                                        day: 'numeric',
                                                                        month: 'short',
                                                                        year: 'numeric',
                                                                    })}{' '}
                                                                    -{' '}
                                                                    {new Date(order.moveOutDate).toLocaleDateString('en-GB', {
                                                                        day: 'numeric',
                                                                        month: 'short',
                                                                        year: 'numeric',
                                                                    })}
                                                                </td>
                                                                <td>{order.noOfGuest || '-'}</td>
                                                                <td>Rs. {order.rentAmount || '-'}</td>
                                                                <td>Rs. {order.totalAmount || '-'}</td>
                                                                <td>Rs. {order.paidAmount || '-'}</td>
                                                                <td>Rs. {order.balanceAmount || '-'}</td>
                                                                <td>
                                                                    {order.orderStatus === 'Success' && <span className="badge rounded-pill badge-soft-success">Success</span>}
                                                                    {order.orderStatus === 'Failed' && <span className="badge rounded-pill badge-soft-danger">Failed</span>}
                                                                    {order.orderStatus === 'Pending' && <span className="badge rounded-pill badge-soft-warning">Pending</span>}
                                                                    {order.orderStatus === 'Initiated' && <span className="badge rounded-pill badge-soft-secondary">Initiated</span>}
                                                                    {order.orderStatus === 'Cancelled' && <span className="badge rounded-pill badge-soft-danger">Cancelled</span>}
                                                                </td>
                                                                <td>
                                                                    {new Date(order.orderCreatedOn).toLocaleDateString('en-GB', {
                                                                        day: 'numeric',
                                                                        month: 'short',
                                                                        year: 'numeric',
                                                                    })}
                                                                    ,{' '}
                                                                    {new Date(order.orderCreatedOn).toLocaleTimeString('en-US', {
                                                                        hour: '2-digit',
                                                                        minute: '2-digit',
                                                                    })}
                                                                </td>
                                                            </tr>
                                                        ))
                                                    )}
                                                </tbody>
                                            </table>

                                        </td>
                                    </tr>
                                )}
                                <ExtendStayModal
                                    showModal={showModal}
                                    handleCloseModal={handleCloseModal}
                                    selectedOrderGuid={selectedOrderGuid}
                                />
                            </React.Fragment>
                        ))
                    )}
                </tbody>
            </table>
        </>
    );
};
