import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import TableHeader from '../Common/TableComponent/TableHeader';
import TablesRow from '../Common/TableComponent/TablesRow';
import { Loading } from '../Common/OtherElements/Loading';
import { paginationSupports, deleteSupport } from '../../services/SupportService';
import Swal from 'sweetalert2';
import { confirmDelete } from '../Common/OtherElements/confirmDeleteClone';
import { TableDataStatusError } from '../Common/OtherElements/TableDataStatusError';
import { handleErrors } from '../../utils/errorHandler';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { Pagination } from '../Common/TableComponent/Pagination';
import { Link } from 'react-router-dom';
import { usePageLevelAccess } from '../../hooks/usePageLevelAccess';

export const ManageSupportManagement = () => {
    const [entriesPerPage, setEntriesPerPage] = useState(30);
    const [currentPage, setCurrentPage] = useState(1);
    const [properties, setProperties] = useState([]);
    const [loading, setLoading] = useState(true);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [searchedTerm, setSearchedTerm] = useState("");
    const [totalCount, setTotalCount] = useState(0);
    const [supportStatus, setsupportStatus] = useState("");
    const [pageAccessDetails, setPageAccessDetails] = useState([]);
    const navigate = useNavigate();
    const PageLevelAccessurl = 'support';
    const { pageAccessData } = usePageLevelAccess(PageLevelAccessurl);

    useEffect(() => {
        if (pageAccessData) {
            if (!pageAccessData.addAccess && !pageAccessData.viewAccess) {
                navigate('/404-error-page');
            } else {
                setPageAccessDetails(pageAccessData);
            }

        } else {
            console.log('No page access details found');
        }
    },[pageAccessData, navigate])

    const searchInputRef = useRef(null);

    useEffect(() => {
        const fetchProperties = async () => {
            setLoading(true);
            const formData = {
                pageSize: entriesPerPage,
                pageNo: currentPage,
                fromDate,
                toDate,
                sParam: searchedTerm,
                supportStatus: supportStatus,
            };
            try {
                const response = await paginationSupports(formData);
                const { data } = response;
                setProperties(data.result);
                setTotalCount(data.result[0]?.totalCount || 0);
            } catch (error) {
                handleErrors(error);
            } finally {
                setLoading(false);
            }
        };

        fetchProperties();
    }, [entriesPerPage, currentPage, fromDate, toDate, searchedTerm, supportStatus]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleEntriesPerPageChange = (e) => {
        setEntriesPerPage(parseInt(e.target.value, 10));
        setCurrentPage(1);
    };

    const handleDelete = async (propertyId) => {
        const confirmed = await confirmDelete('Support');
        if (confirmed) {
            try {
                await deleteSupport(propertyId);
                setProperties(prev => prev.filter(item => item.id !== propertyId));
                Swal.fire('Deleted!', 'The Support has been deleted successfully.', 'success');
            } catch (error) {
                handleErrors(error);
            }
        }
    };

    const handleSearchClick = () => {
        setSearchedTerm(searchTerm);
    };

    useEffect(() => {
        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, []);

    const totalPages = Math.ceil(totalCount / entriesPerPage);

    return (
        <>
            <style>
                {`
                    .ri-pencil-fill:before {
                      display:none;
                    }
                `}
            </style>
            {pageAccessDetails.viewAccess ? (
                <div className="row">
                    <div className="col-xxl-12">
                        <div className="card mt-xxl-n5">
                            <div className="card-header">
                                <h5 className="mb-sm-2 mt-sm-2">Manage Support</h5>
                            </div>
                            <div className="card-body manage-amenity-master-card-body">
                                <div className="responsive-filter-type mb-3">
                                    <div className="entries-dropdownx">
                                        <label htmlFor="entriesPerPage" className="form-label me-2">Show entries:</label>
                                        <select
                                            className="form-select"
                                            id="entriesPerPage"
                                            value={entriesPerPage}
                                            onChange={handleEntriesPerPageChange}
                                        >
                                            <option value="30">30</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="200">200</option>
                                        </select>
                                    </div>
                                    <div className="date-filters">
                                        <label htmlFor="fromDate" className="form-label me-2">From Date:</label>
                                        <Flatpickr
                                            id="fromDate"
                                            className="form-control"
                                            placeholder='Select From Date'
                                            value={fromDate}
                                            onChange={([date]) => setFromDate(date)}
                                            options={{
                                                dateFormat: "Y-m-d",
                                                monthSelectorType: 'static',
                                            }}
                                        />
                                    </div>
                                    <div className="date-filters">
                                        <label htmlFor="toDate" className="form-label me-2">To Date:</label>
                                        <Flatpickr
                                            id="toDate"
                                            className="form-control"
                                            placeholder='Select To Date'
                                            value={toDate}
                                            onChange={([date]) => setToDate(date)}
                                            options={{
                                                dateFormat: "Y-m-d",
                                                monthSelectorType: 'static',
                                            }}
                                        />
                                    </div>
                                    <div className="">
                                        <label htmlFor="search" className="form-label me-2">Support Status:</label>
                                        <select
                                            className="form-select"
                                            value={supportStatus}
                                            onChange={(e) => setsupportStatus(e.target.value)}
                                        >
                                            <option value="">Select..</option>
                                            <option value="Created">Created</option>
                                            <option value="InProgress">InProgress</option>
                                            <option value="Closed">Closed</option>
                                            <option value='Resolved'>Resolved</option>
                                            <option value='Opened'>Opened</option>
                                        </select>
                                    </div>
                                    <div className="search-input">
                                        <label htmlFor="search" className="form-label me-2">Search:</label>
                                        <input
                                            type="text"
                                            id="search"
                                            className="form-control"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            placeholder="Search...."
                                            ref={searchInputRef}
                                        />
                                    </div>

                                    <div className="pt-2">
                                        <button
                                            className="btn btn-secondary btn-properties-search"
                                            onClick={handleSearchClick}
                                        >
                                            Search
                                        </button>
                                    </div>
                                </div>
                                {loading ? (
                                    <Loading />
                                ) : (
                                    <div className='table-responsive'>
                                        <table className="table align-middle table-bordered">
                                            <TableHeader columns={['#', 'User Name', 'Property Name', 'Type of Support', 'Description', 'Status', 'Action']} />
                                            <tbody className="manage-amenity-master-table-values">
                                                {properties.length > 0 ? (
                                                    properties.map((item, index) => (
                                                        <TablesRow
                                                            key={item.id}
                                                            rowData={{
                                                                id: (currentPage - 1) * entriesPerPage + index + 1,
                                                                userName: (
                                                                    <Link to={`detail/${item.id}`} style={{ textDecoration: 'none' }}>
                                                                        {item.userName}
                                                                    </Link>
                                                                ),
                                                                propertyName: item.propertyName ? (
                                                                    <>
                                                                        <span>{item.propertyName || '-'}</span>
                                                                        <br />
                                                                        <label>Flat: </label>
                                                                        <span>{item.flatNo || '-'}</span>
                                                                    </>
                                                                ) : '-',
                                                                typeOfSupport: item.typeOfSupport || '-',
                                                                description: item.description || '-',

                                                                status: (
                                                                    <span
                                                                        className={`badge rounded-pill ${item.supportStatus === 'Created' ? 'badge-soft-primary' :
                                                                            item.supportStatus === 'InProgress' ? 'badge-soft-success' :
                                                                                item.supportStatus === 'Closed' ? 'badge-soft-danger' : 'badge-soft-warning'
                                                                            }`}
                                                                    >
                                                                        {item.supportStatus}
                                                                    </span>
                                                                ),

                                                            }}
                                                            columns={['id', 'userName', 'propertyName', 'typeOfSupport', 'description', 'status']}
                                                            hideIcons={false}
                                                            showIcons={false}
                                                            onEdit={() => {
                                                                navigate(`update/${item.id}`);
                                                                window.scrollTo({
                                                                    top: 0,
                                                                    behavior: 'smooth'
                                                                });
                                                            }}
                                                            onDelete={() => handleDelete(item.id)}
                                                            pageLevelAccessData={pageAccessDetails}
                                                        />
                                                    ))
                                                ) : (
                                                    <TableDataStatusError colspan="10" />
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                                <Pagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onPageChange={handlePageChange}
                                    totalEntries={totalCount}
                                    entriesPerPage={entriesPerPage}
                                />
                            </div>


                        </div>
                    </div>
                </div>
            ) : ''}
        </>
    );
};
