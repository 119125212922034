import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchOnboardingCustomersById } from '../../services/onboardingCustomerService';
import { OnboardCustomerDetails } from './OnboardCustomerDetails';
import { FlatmateDetails } from './FlatmateDetails';
import { WitnessDetails } from './WitnessDetails';
import { OrderDetails } from './OrderDetails';
import { KycDetails } from './KycDetails';
import { useNavigate } from 'react-router-dom';
import { usePageLevelAccess } from '../../hooks/usePageLevelAccess';

export const OnboardCustomersTab = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('CustomerDetails');
    const [customerData, setCustomerData] = useState([]);
    const { customerGuid } = useParams();
    const [pageAccessDetails, setPageAccessDetails] = useState([]);
    const PageLevelAccessurl = 'onboarding-customers/detail/:customerGuid';
    const { pageAccessData } = usePageLevelAccess(PageLevelAccessurl);

    useEffect(() => {
        if (pageAccessData) {
            if (!pageAccessData.viewAccess) {
                navigate('/404-error-page');
            } else {
                setPageAccessDetails(pageAccessData);
            }

        } else {
            console.log('No page access details found');
        }
    },[pageAccessData, navigate])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const apidata = await fetchOnboardingCustomersById(customerGuid);
                setCustomerData(apidata.result);
            } catch (error) {
                console.error("Error fetching customer data:", error);
            }
        };
        fetchData();
    }, [customerGuid]);

    const formatDateTime = (dateString) => {
        if (!dateString) return "-";
        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        };
        return new Date(dateString).toLocaleString('en-US', options);
    };


    return (
        <>
        {pageAccessDetails.viewAccess ? (
            <>
            <div className="card mt-n4 mx-n4 bg-soft-info">
                <div>
                    <div className="card-body pb-0 px-4">
                        <div className="row mb-3">
                            <div className="col-md">
                                <div className="row align-items-center g-3">

                                    <div className="col-md">
                                        <div>
                                            <h4 className="fw-bold">Customer Name : <span>{customerData.firstName} {customerData.lastName}</span><span style={{ fontSize: '12px' }} className={`badge text-bg-${customerData.status === 'Active' ? 'success' : 'danger'} badge-border ms-3`}>
                                                {customerData.status === 'Active' ? 'Active' : 'Inactive'}
                                            </span>
                                                <span style={{ fontSize: '12px' }} className={`badge text-bg-${customerData.rentStatus === 'Active' ? 'success' : 'danger'} badge-border ms-3`}>
                                                    <i className="ri-shopping-cart-2-line"></i> {customerData.rentStatus === 'Active' ? 'Active' : 'Inactive'}
                                                </span>
                                                <span style={{ float: 'right', cursor: 'pointer' }} title="Edit" onClick={() => navigate(`/onboarding-customers/update/${customerGuid}`)}>
                                                    <i className="mdi mdi-square-edit-outline fs-20"></i>
                                                </span></h4>

                                            <div className="hstack gap-3 flex-wrap">
                                                <div>Joined On : <span className="fw-medium">{customerData.registeredOn ? formatDateTime(customerData.registeredOn) : 'Not registered date'}</span></div>
                                                <div className="vr"></div>
                                                <div>Last Logged In : <span className="fw-medium">{customerData.lastLoggedIn ? formatDateTime(customerData.lastLoggedIn) : 'No Log details found'}</span></div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ul className="nav nav-tabs-custom border-bottom-0" role="tablist">
                            <li className="nav-item" id="documentsTab" role="presentation">
                                <a className={`nav-link ${activeTab === 'CustomerDetails' ? 'active' : ''}`} data-bs-toggle="tab" href="#profile1" role="tab" aria-selected={activeTab === 'CustomerDetails'} onClick={() => setActiveTab('CustomerDetails')}
                                >
                                    Customer Details
                                </a>
                            </li>
                            <li className="nav-item" id="teamsTab" role="presentation">
                                <a className={`nav-link ${activeTab === 'FlatmateDetails' ? 'active' : ''}`} data-bs-toggle="tab" href="#messages1" role="tab" aria-selected={activeTab === 'FlatmateDetails'} onClick={() => setActiveTab('FlatmateDetails')}
                                >
                                    Flatmate Details
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className={`nav-link ${activeTab === 'witness' ? 'active' : ''}`} data-bs-toggle="tab" href="#home1" role="tab" aria-selected={activeTab === 'witness'} onClick={() => setActiveTab('witness')}
                                >
                                    Witness
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className={`nav-link ${activeTab === 'kyc' ? 'active' : ''}`} data-bs-toggle="tab" href="#kyc1" role="tab" aria-selected={activeTab === 'kyc'} onClick={() => setActiveTab('kyc')}
                                >
                                    KYC
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className={`nav-link ${activeTab === 'orders' ? 'active' : ''}`} data-bs-toggle="tab" href="#orders1" role="tab" aria-selected={activeTab === 'orders'} onClick={() => setActiveTab('orders')}
                                >
                                    Orders
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>


            <div className="row">
                <div className="col-xxl-12">
                    <div className="card mt-xxl-n5">

                        <div className="tab-content text-muted">
                            <div className={`tab-pane ${activeTab === 'witness' ? 'active show' : ''}`} id="home1" role="tabpanel">
                                <div className="d-flex">
                                    <div className="flex-grow-1 table-responsive">
                                        <WitnessDetails customerData={customerData} />
                                    </div>
                                </div>
                            </div>
                            <div className={`tab-pane ${activeTab === 'CustomerDetails' ? 'active show' : ''}`} id="profile1" role="tabpanel">
                                <div className="d-flex">
                                    <div className="flex-grow-1 ">
                                        <OnboardCustomerDetails customerData={customerData} />
                                    </div>
                                </div>
                            </div>
                            <div className={`tab-pane ${activeTab === 'FlatmateDetails' ? 'active show' : ''}`} id="messages1" role="tabpanel">
                                <div className="d-flex">
                                    <div className="flex-grow-1 table-responsive">
                                        <FlatmateDetails customerData={customerData} />
                                    </div>
                                </div>
                            </div>
                            <div className={`tab-pane ${activeTab === 'kyc' ? 'active show' : ''}`} id="kyc1" role="tabpanel">
                                <div className="d-flex">
                                    <div className="flex-grow-1 ms-2">
                                        <KycDetails customerData={customerData} />

                                    </div>
                                </div>
                            </div>
                            <div className={`tab-pane ${activeTab === 'orders' ? 'active show' : ''}`} id="orders1" role="tabpanel">
                                <div className="d-flex">
                                    <div className="flex-grow-1 table-responsive">
                                        <OrderDetails customerGuid={customerGuid} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            </>
            ) : ''}
        </>
    );
};
