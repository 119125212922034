import axiosInstance from '../Interceptors/axiosInstance.jsx';
import Cookies from 'js-cookie';

const headers = {
  'accept': '*/*',
  'Authorization': `Bearer ${Cookies.get('accessToken')}`,
  'Content-Type': 'application/json',
};

export const createCity = async (cityData) => {
    const response = await axiosInstance.post('location/city', { cityName: cityData.cityName, }, { headers });
    return response.data;
};

export const fetchCities = async () => {
    const response = await axiosInstance.get('location/city/all', { headers });
    return response.data;
};

export const deleteCity = async (cityId) => {
    const response = await axiosInstance.delete(`location/city/${cityId}`, { headers });
    return response.data;
};

export const fetchCityById = async (cityId) => {
    const response = await axiosInstance.get(`location/city/${cityId}`, { headers });
    return response.data.result;
};

export const updateCity = async (cityData) => {
    const response = await axiosInstance.put('location/city', cityData, { headers });
    return response.data;
};