import React, { useState, useEffect, useCallback } from 'react';
import { updateAssignedSupervisor, fetchAssignedSupervisorById } from '../../../services/AssignProperties';
import { addSupervisor } from '../../../services/AssignProperties';
import { validateAssignProperties } from '../../../utils/validation';
import { toast } from 'react-toastify';
import { fetchCities } from '../../../services/cityService';
import { handleErrors } from '../../../utils/errorHandler';
import { fetchLocations } from '../../../services/locationService';
import { fetchPropertiesByLocations } from '../../../services/AssignProperties';
import { fetchFlats } from '../../../services/FlatMasterService';
import "react-toastify/dist/ReactToastify.css";
import { fetchSupervisorsList } from '../../../services/AssignProperties';
import { FormControl, Select, MenuItem, Checkbox, ListItemText, OutlinedInput } from '@mui/material';
import ComponentHeader from '../../Common/OtherElements/ComponentHeader';
import AsyncSelect from 'react-select/async';

export const AddSupervisor = ({ editMode = false, initialData = {}, onSuccess, setSelectedPageGroup, setEditMode }) => {
  const [formData, setFormData] = useState({ city: '', locationName: '', propertyGuid: '', userGuid: '', flatGuid: [] });
  const [errors, setErrors] = useState({ city: '', locationName: '', propertyGuid: '', userGuid: '', flatGuid: '' });
  const [apiError, setApiError] = useState('');
  const [cities, setCities] = useState([]);
  const [locations, setLocations] = useState([]);
  const [properties, setProperties] = useState([]);
  const [flats, setFlats] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedProperty, setSelectedProperty] = useState('');
  const [supervisors, setSupervisors] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    const fetchCitiesData = async () => {
      try {
        const citiesData = await fetchCities();
        setCities(citiesData.result);
      } catch (error) {
        handleErrors(error);
      }
    };
    fetchCitiesData();
  }, []);

  useEffect(() => {
    const loadLocations = async () => {
      if (selectedCity) {
        try {
          const locationData = await fetchLocations();
          const filteredLocations = locationData.result.filter(location => location.cityName === selectedCity);
          setLocations(filteredLocations);
        } catch (error) {
          handleErrors(error);
        }
      } else {
        setLocations([]);
      }
    };
    loadLocations();
  }, [selectedCity]);

  useEffect(() => {
    const fetchSupervisors = async () => {
      try {
        const supervisorData = await fetchSupervisorsList();
        setSupervisors(supervisorData);
      } catch (error) {
        handleErrors(error);
      }
    };
    fetchSupervisors();
  }, []);

  useEffect(() => {
    const fetchProperties = async () => {
      if (selectedLocation) {
        try {
          const propertyData = await fetchPropertiesByLocations(selectedLocation);
          setProperties(propertyData);
        } catch (error) {
          handleErrors(error);
        }
      } else {
        setProperties([]);
      }
    };
    fetchProperties();
  }, [selectedLocation]);

  useEffect(() => {
    const fetchFlatsData = async () => {
      if (selectedProperty) {
        try {
          const flatData = await fetchFlats(selectedProperty);
          setFlats(flatData);
        } catch (error) {
          handleErrors(error);
        }
      } else {
        setFlats([]);
      }
    };
    fetchFlatsData();
  }, [selectedProperty]);

  useEffect(() => {
    const fetchData = async () => {
      if (editMode && initialData.id) {
        try {
          const data = await fetchAssignedSupervisorById(initialData.id);

          const city = data.result.city || '';
          const locationName = data.result.locationName || '';
          const propertyGuid = data.result.propertyGuid || '';
          setSelectedCity(city);
          setSelectedLocation(locationName);
          setSelectedProperty(propertyGuid);

          setFormData({
            city: city,
            locationName: locationName,
            propertyGuid: propertyGuid,
            userGuid: data.result.userGuid || '',
            flatGuid: data.result.flatGuid || [],
          });
        } catch (error) {
          handleErrors(error);
        }
      } else {
        setFormData({ city: '', locationName: '' });
      }
    };

    fetchData();
  }, [editMode, initialData]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFlatChange = (event) => {
    const {
      target: { value },
    } = event;
    setFormData((prevData) => ({
      ...prevData,
      flatGuid: Array.isArray(value) ? value : [],
    }));
  };

  const loadOptions = (inputValue, callback) => {
    const filteredProperties = properties.filter((property) =>
      property.propertyName.toLowerCase().includes(inputValue.toLowerCase())
    );

    setTimeout(() => {
      callback(
        filteredProperties.map((property) => ({
          label: property.propertyName,
          value: property.propertyGuid,
        }))
      );
    }, 1000);
  };

  const loadCitiesOptions = (inputValue, callback) => {
    const filteredCities = cities.filter((city) =>
      city.cityName.toLowerCase().includes(inputValue.toLowerCase())
    );

    setTimeout(() => {
      callback(
        filteredCities.map((city) => ({
          label: city.cityName,
          value: city.cityName,
        }))
      );
    }, 1000);
  };

  const loadLocationOptions = (inputValue, callback) => {
    const filteredLocations = locations.filter((location) =>
      location.locationName.toLowerCase().includes(inputValue.toLowerCase())
    );

    setTimeout(() => {
      callback(
        filteredLocations.map((location) => ({
          label: location.locationName,
          value: location.locationName,
        }))
      );
    }, 1000);
  };

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    const { valid, errors: validationErrors } = validateAssignProperties(formData);
    setErrors(validationErrors);

    if (valid) {
      setApiError('');
      try {
        if (editMode) {
          setIsButtonDisabled(true);
          await updateAssignedSupervisor({ ...formData, id: initialData.id });
          toast.success('Supervisor updated successfully!');
          setIsButtonDisabled(false);
          setEditMode(false);
        } else {
          setIsButtonDisabled(true);
          await addSupervisor({
            formData
          });
          toast.success('Supervisor added successfully!');
          setIsButtonDisabled(false);
        }
        setFormData({ city: '', locationName: '', propertyGuid: '', userGuid: '', flatGuid: [] });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        handleErrors(error);
        setIsButtonDisabled(false);
      }
    }
  }, [formData, editMode, initialData, onSuccess, setEditMode]);

  const handleAddNewClick = () => {
    setSelectedCity('');
    setSelectedLocation('');
    setSelectedProperty('');
    setFormData({
      city: selectedCity,
      locationName: selectedLocation,
      propertyGuid: selectedProperty,
      userGuid: '',
      flatGuid: [],
    });
    setErrors({ city: '', locationName: '', propertyGuid: '', userGuid: '', flatGuid: [] });
    setApiError('');
    setSelectedPageGroup(null);
    setEditMode(false);
  };

  return (
    <>

      <ComponentHeader title='Supervisor' />
      <div className="row">
        <div className="col-xxl-12">
          <div className="card mt-xxl-n5">
            <div className="card-header">
              <h5 className="mb-sm-1 mt-sm-1">{editMode ? 'Update Supervisor' : 'Add Supervisor'}</h5>
            </div>

            <div className="card-body p-4">
              <form onSubmit={handleSubmit} method="POST">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="city" className="form-label">Select City <span className='required-field'>*</span></label>
                      <AsyncSelect
                        cacheOptions
                        loadOptions={loadCitiesOptions}
                        defaultOptions={cities.map((city) => ({
                          label: city.cityName,
                          value: city.cityName,
                        }))}
                        onChange={(selectedOption) => {
                          setSelectedCity(selectedOption?.value || '');
                          handleInputChange({
                            target: {
                              name: 'city',
                              value: selectedOption?.value || '',
                            },
                          });
                        }}
                        value={{
                          label: cities.find((city) => city.cityName === formData.city)?.cityName,
                          value: formData.city,
                        }}
                        isClearable
                        placeholder="Select City"
                        className={errors.city ? 'is-invalid' : ''}
                      />

                      {errors.city && <div className="invalid-feedback">{errors.city}</div>}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="locationName" className="form-label">Location Name <span className='required-field'>*</span></label>
                      <AsyncSelect
                        cacheOptions
                        loadOptions={loadLocationOptions} 
                        defaultOptions={locations.map((location) => ({
                          label: location.locationName,
                          value: location.locationName,
                        }))}
                        onChange={(selectedOption) => {
                          setSelectedLocation(selectedOption?.value || '');
                          handleInputChange({
                            target: {
                              name: 'locationName',
                              value: selectedOption?.value || '',
                            },
                          });
                        }}
                        value={{
                          label: locations.find((location) => location.locationName === formData.locationName)?.locationName,
                          value: formData.locationName,
                        }}
                        isClearable
                        placeholder="Select Location"
                        className={errors.locationName ? 'is-invalid' : ''}
                      />
                      {errors.locationName && <div className="invalid-feedback">{errors.locationName}</div>}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="propertyGuid" className="form-label">Property Name <span className='required-field'>*</span></label>
                      <AsyncSelect
                        cacheOptions
                        loadOptions={loadOptions}
                        defaultOptions={properties.map((property) => ({
                          label: property.propertyName,
                          value: property.propertyGuid,
                        }))}
                        onChange={(selectedOption) => {
                          setSelectedProperty(selectedOption?.value || '');
                          handleInputChange({
                            target: {
                              name: 'propertyGuid',
                              value: selectedOption?.value || '',
                            },
                          });
                        }}
                        value={{
                          label: properties.find((property) => property.propertyGuid === formData.propertyGuid)?.propertyName,
                          value: formData.propertyGuid,
                        }}
                        isClearable
                        placeholder="Select Property"
                        className={errors.propertyGuid ? 'is-invalid' : ''}
                      />
                      {errors.propertyGuid && <div className="invalid-feedback">{errors.propertyGuid}</div>}
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="locationName" className="form-label">Select Flats</label>
                      <FormControl variant="outlined"
                        fullWidth
                        margin="normal"
                        sx={{
                          m: 1,
                          width: '100%',
                        }}>
                        <Select
                          labelId="flat-multiple-checkbox-label"
                          className={`form-select ${errors.flatGuid ? 'is-invalid' : ''}`}
                          id="flat-multiple-checkbox"
                          multiple
                          value={Array.isArray(formData.flatGuid) ? formData.flatGuid : []}
                          onChange={handleFlatChange}
                          input={<OutlinedInput label="Select Flat" />}
                          renderValue={(selected) =>
                            selected
                              .map((flatGuid) => flats.find((flat) => flat.flatGuid === flatGuid)?.flatNo)
                              .join(', ')
                          }
                        >
                          {flats.length > 0 ? (
                            flats.map((flat) => (
                              <MenuItem key={flat.flatGuid} value={flat.flatGuid}>
                                <Checkbox checked={formData.flatGuid && formData.flatGuid.includes(flat.flatGuid)} />
                                <ListItemText primary={flat.flatNo} />
                              </MenuItem>
                            ))
                          ) : <MenuItem> No Flats Found </MenuItem>}
                        </Select>
                        {errors.flatGuid && <div className="invalid-feedback">{errors.flatGuid}</div>}
                      </FormControl>

                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="supervisorId" className="form-label">Supervisor <span className='required-field'>*</span></label>
                      <select
                        name="userGuid"
                        className={`form-select ${errors.userGuid ? 'is-invalid' : ''}`}
                        value={formData.userGuid}
                        onChange={handleInputChange}
                      >
                        <option value="">Select Supervisor</option>
                        {supervisors.map((supervisor) => (
                          <option key={supervisor.userGuid} value={supervisor.userGuid}>
                            {supervisor.userName}
                          </option>
                        ))}
                      </select>
                      {errors.userGuid && <div className="invalid-feedback">{errors.userGuid}</div>}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="pt-4">
                      <button type="submit" className="btn btn-secondary pt-1 pb-1 p-3" disabled={isButtonDisabled}>
                        {isButtonDisabled ? (editMode ? 'Updating' : 'Saving') : (editMode ? 'Update' : 'Save')}
                      </button>
                      {editMode && (
                        <button type="button" onClick={handleAddNewClick} className="btn btn-danger ms-1 pt-1 pb-1 p-3">
                          Cancel
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                {apiError && <div className="alert alert-danger">{apiError}</div>}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
